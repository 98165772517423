import React, { useContext, useEffect, useState } from "react";
import Game from "../Game/Game";
import RightSideBar from "../RightSideBar/RightSideBar";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import TeamNames from "../TopBar/TeamNames";
import Goal from "../TopBar/Goal";
import { UIContext } from "../../contexts/UIContext";
import ClockEdit from "../ClockEdit/ClockEdit";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Goals from "../Goals/Goals";
import Penalties from "../Penalties/Penalties";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import EFLGame from "../Game/EFLGame";
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        player {
          _id
          opta_ID
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
    away {
      squad {
        _id

        shirt_number
        opta_shirt_number
        player {
          _id
          opta_ID
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      opta_team_stats
      opta_player_stats
      stadium {
        opta_name
        name
      }
      competition {
        name
        badge
        competition_type
        colour
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      live_data
      home_formation
      away_formation
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      config
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      opta_team_stats
      opta_player_stats
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;
const OPTA_EVENT_SUBSCRIPTION = gql`
  subscription newOptaEvent($id: String!) {
    newOptaEvent(id: $id) {
      _id
      typeId
      timeMin
      timeSec
      qualifier
      playerId
      contestantId
    }
  }
`;
const OPTA_EVENT_BY_GAME = gql`
  query OptaEventByGame($opta_ID: String) {
    OptaEventByGame(opta_ID: $opta_ID) {
      _id
      typeId
      timeMin
      timeSec
      qualifier
      playerId
      contestantId
    }
  }
`;
const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;
export default function Live({ setSelectedPlayer, selectedPlayer }) {
  const { getGroupsBySeason } = useContext(APIContext);
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [group, setGroup] = useState();
  const [groups, setGroups] = useState();
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();
  const [optaEvents, setOptaEvents] = useState();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID, {
    fetchPolicy: "network-first",
  });
  const [
    getOptaEvent,
    { data: optaEventData, subscribeToMore: subscribeToMoreOPTA },
  ] = useLazyQuery(OPTA_EVENT_BY_GAME, {
    fetchPolicy: "network-first",
  });

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
      getOptaEvent({ variables: { opta_ID: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          updateGraphic("ScoreBug", {
            game: newGame,
          });
          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMoreOPTA) {
      console.log("Subscribed");
      unsubscribe = subscribeToMoreOPTA({
        document: OPTA_EVENT_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.newOptaEvent;
          debugger;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMoreOPTA, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
    }
  }, [data]);

  useEffect(() => {
    if (optaEventData) {
      setOptaEvents(optaEventData.OptaEventByGame);
    }
  }, [optaEventData]);

  const { goal, setGoal } = useContext(UIContext);
  const {
    previewGraphic,
    animateGraphic,
    graphicInPreview,
    animateOffGraphic,
    updateGraphic,
    clearPVW,
  } = useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);

  useEffect(() => {
    getGroupsBySeason({ seasonId: game?.season?._id })?.then((data) => {
      let g = data?.find(
        (g) =>
          g.teams?.findIndex((t) => t?.team?._id === game?.home_team?._id) > -1
      );

      setGroups(data);
      setGroup(g);
    });
  }, [game]);

  if (!game) return <div>Loading</div>;
  return (
    game && (
      <Main>
        <Content>
          {!goal && (
            <TeamNames
              setSelectedPlayer={setSelectedPlayer}
              homeTeam={game.home_team}
              awayTeam={game.away_team}
              game={game}
              group={group}
            />
          )}
          {goal && (
            <Goal
              goal={goal}
              setGoal={setGoal}
              cancel={() => {
                updateGame({
                  id: game._id,
                  home_scorers: game.home_scorers.filter((g) => g.player),
                  away_scorers: game.away_scorers.filter((g) => g.player),
                });
                setGoal();
              }}
            />
          )}

          <Switch>
            <Route path="/:gameID/penalties">
              <Penalties game={game} />
            </Route>
            <Route path="/:gameID/clock">
              <ClockEdit game={game} />
            </Route>
            <Route path="/:gameID/goals">
              <Goals
                game={game}
                homeLineup={game?.lineup?.home}
                awayLineup={game?.lineup?.away}
                setGoal={setGoal}
              />
            </Route>

            <Route path="/:gameID">
              <Game
                setHomeManager={setHomeManager}
                setAwayManager={setAwayManager}
                setGoal={setGoal}
                goal={goal}
                game={game}
                setSelectedPlayer={setSelectedPlayer}
                selectedPlayer={selectedPlayer}
                optaEvents={optaEvents}
              />
            </Route>
          </Switch>
        </Content>

        <RightBar>
          <RightSideBar
            group={group}
            groups={groups}
            game={game}
            homeManager={homeManager}
            awayManager={awayManager}
            previewGraphic={previewGraphic}
            graphicInPreview={graphicInPreview}
            animateGraphic={animateGraphic}
            animateOffGraphic={animateOffGraphic}
            updateGraphic={updateGraphic}
            selectedPlayer={selectedPlayer}
            clearPreview={clearPVW}
          />
        </RightBar>
      </Main>
    )
  );
}
