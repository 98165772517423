export class HeroFormation {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "HeroFormation";
    this.downloadPage = "7400";
    // this.page = "45";
    this.page = "7400";
    this.viz = viz;
    this.stage = 0;
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.viz.getPage(this.downloadPage);

    this.buildHome(xml, options);
    this.buildAway(xml, options);
    this.stage = 0;
    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    this.onAir = true;
    if (this.stage === 0) {
      this.viz.take(this.page);
    } else {
      this.viz.continue(this.page);
    }
    this.stage++;
  }
  animateOff({ options }) {
    this.onAir = false;
    this.stage = 0;
    this.viz.takeOut(this.page);
  }

  buildHome(xml, options) {
    let homeTeam = options.game.home_team;

    let players = options.game.live_data.home_team_lineup.sort(
      (a, b) => a.order - b.order
    );

    let homeSky = options.sky.find((sky) => sky.opta_ID === homeTeam.opta_ID);

    xml.payload.field.find((item) => {
      // GK DEF MID1 MID2 ATT
      return item.attr.name === "000"; // set formation eg    1  4   2    3    1
    }).value =
      "1" +
      ((options.game.live_data.home_formation &&
        options.game.live_data.home_formation.name) ||
        "352"); // i think this is used for how many players to show per page

    xml.payload.field.find((item) => {
      return item.attr.name === "011"; // Home Team Name
    }).value = homeSky.lineup_intro;

    xml.payload.field.find((item) => {
      return item.attr.name === "010"; // Home Team Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.home_team.variables["badge"];

    var manager = xml.payload.field.find((item) => {
      return item.attr.name === "100"; // Home Manager
    });
    let managerData = options.game.home_team_manager;

    // manager image
    manager.value =
      " F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\" +
      (managerData.head_shots && managerData.head_shots.length > 0
        ? managerData.head_shots[0].url
        : "");
    // eg F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\NEWCASTLE\\HOME\\TORSO\\Mikel_Arteta_T_C.png

    // manager.FirstName
    manager.field.find((item) => {
      return item.attr.name === "FirstName";
    }).value = managerData.first_name_upper;

    // manager.Surname
    manager.field.find((item) => {
      return item.attr.name === "Surname";
    }).value = managerData.last_name_upper;

    // manager.Title
    manager.field.find((item) => {
      return item.attr.name === "Title";
    }).value = "Manager"; // Head coach, manager, caretaker manager etc.

    // loop through starting 11 (Players left)
    players
      .filter((p) => p.order <= 11)
      .forEach((player, index) => {
        var playerTabnNo = index + 11 + "0";
        var formationImageTabNo = index + 11 + "2";

        console.log(playerTabnNo, "****", formationImageTabNo);

        var playerTab = xml.payload.field.find((item) => {
          return item.attr.name === playerTabnNo;
        }); // Tab Group
        var formationTab = xml.payload.field.find((item) => {
          return item.attr.name === formationImageTabNo;
        }); // Tab Group

        // start player Tab attributes
        // Hero Image (root)
        playerTab.value =
          "F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\" +
          homeTeam.name +
          "\\HOME\\HERO\\" +
          player.shirt_number +
          ".png"; // eg F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\NEWCASTLE\\HOME\\HERO\\1.png

        // playerTab.FirstName
        playerTab.field.find((item) => {
          return item.attr.name === "FirstName";
        }).value = player.player.first_name_upper;

        // playerTab.Surname
        playerTab.field.find((item) => {
          return item.attr.name === "Surname";
        }).value =
          player.player.last_name_upper +
          "    " +
          (player.captain ? "(C)" : "");

        // playerTab.Flag
        playerTab.field.find((item) => {
          return item.attr.name === "Flag";
        }).value = player.nationality.code.toLowerCase(); // all lowercase and built into the archive

        // playerTab.Number
        playerTab.field.find((item) => {
          return item.attr.name === "Number";
        }).value = player.shirt_number;

        // end player Tab attributes
        // ***********************************************************
        // start formation Tab attributes
        // Formation Image (root)
        formationTab.value =
          "F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\" +
          homeTeam.name +
          "\\HOME\\STANDARD\\" +
          player.shirt_number +
          ".png"; // eg F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\NEWCASTLE\\HOME\\STANDARD\\1.png

        // FormationTab.active
        formationTab.field.find((item) => {
          return item.attr.name === "active";
        }).value = "1";

        // FormationTab.image_position
        formationTab.field.find((item) => {
          return item.attr.name === "image_position";
        }); //.value = '1';

        // FormationTab.image_scaling
        formationTab.field.find((item) => {
          return item.attr.name === "image_scaling";
        }); //.value = '1';

        let x =
          options.game.live_data.home_formation.positions[player.order - 1].x *
          4.5;
        let y =
          options.game.live_data.home_formation.positions[player.order - 1].y *
          2;
        // FormationTab.position
        let position = (formationTab.field.find((item) => {
          return item.attr.name === "position";
        }).value = x + "  " + y + " 0");
      });

    for (let i = 2; i <= 10; i++) {
      var nameTab = "2" + i + "0";
      var numTab = "2" + i + "1";

      if (i === 10) {
        nameTab = "295";
        numTab = "296";
      }
      console.log("home name", nameTab, i);
      try {
        xml.payload.field.find((item) => {
          return item.attr.name === numTab; // Number
        }).value = "";

        xml.payload.field.find((item) => {
          return item.attr.name === nameTab; //  name
        }).value = "";
      } catch (err) {}
    }
    // loop through subs (Players Right)
    players
      .filter((p) => p.order > 11)
      .forEach((player, index) => {
        // ** NOTE SCENE LIMITED TO 7 SUBS ***
        var nameTab = "2" + (index + 2) + "0";
        var numTab = "2" + (index + 2) + "1";

        xml.payload.field.find((item) => {
          return item.attr.name === numTab; // Number
        }).value = player.shirt_number;

        xml.payload.field.find((item) => {
          return item.attr.name === nameTab; //  name
        }).value = player.player.last_name_upper;
      });
  }

  buildAway(xml, options) {
    let awayTeam = options.game.away_team;

    let players = options.game.live_data.away_team_lineup.sort(
      (a, b) => a.order - b.order
    );

    let awaySky = options.sky.find((sky) => sky.opta_ID === awayTeam.opta_ID);

    xml.payload.field.find((item) => {
      // GK DEF MID1 MID2 ATT
      return item.attr.name === "001"; // set formation eg    1  4   2    3    1
    }).value =
      "1" +
        (options.game.live_data.away_formation &&
          options.game.live_data.away_formation.name) || "352"; // i think this is used for how many players to show per page

    xml.payload.field.find((item) => {
      return item.attr.name === "021"; // Away Team Name
    }).value = awaySky.lineup_intro;

    xml.payload.field.find((item) => {
      return item.attr.name === "020"; // Away Team Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.away_team.variables["badge"];

    let managerData = options.game.away_team_manager;

    var manager = xml.payload.field.find((item) => {
      return item.attr.name === "300"; // Away Manager
    });

    // // manager image
    manager.value =
      " F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\" +
      (managerData.head_shots && managerData.head_shots.length > 0
        ? managerData.head_shots[0].url
        : "");
    // // eg F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\NEWCASTLE\\HOME\\TORSO\\Mikel_Arteta_T_C.png

    // // manager.FirstName
    manager.field.find((item) => {
      return item.attr.name === "FirstName";
    }).value = managerData.first_name_upper;

    // // manager.Surname
    manager.field.find((item) => {
      return item.attr.name === "Surname";
    }).value = managerData.last_name_upper;

    // // manager.Title
    manager.field.find((item) => {
      return item.attr.name === "Title";
    }).value = "Manager"; // Head coach, manager, caretaker manager etc.

    // loop through starting 11 (Players left)
    players
      .filter((p) => p.order <= 11)
      .forEach((player, index) => {
        var playerTabnNo = index + 31 + "0";
        var formationImageTabNo = index + 31 + "2";

        // console.log(playerTabnNo, "****", formationImageTabNo);

        var playerTab = xml.payload.field.find((item) => {
          return item.attr.name === playerTabnNo;
        }); // Tab Group
        var formationTab = xml.payload.field.find((item) => {
          return item.attr.name === formationImageTabNo;
        }); // Tab Group

        // start player Tab attributes
        // Hero Image (root)
        playerTab.value =
          "F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\" +
          awayTeam.name +
          "\\HOME\\HERO\\" +
          player.shirt_number +
          ".png"; // eg F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\NEWCASTLE\\HOME\\HERO\\1.png

        // playerTab.FirstName
        playerTab.field.find((item) => {
          return item.attr.name === "FirstName";
        }).value = player.player.first_name_upper;

        // playerTab.Surname
        playerTab.field.find((item) => {
          return item.attr.name === "Surname";
        }).value =
          player.player.last_name_upper +
          "    " +
          (player.captain ? "(C)" : "");

        // playerTab.Flag
        playerTab.field.find((item) => {
          return item.attr.name === "Flag";
        }).value = player.nationality.code.toLowerCase(); // all lowercase and built into the archive

        // playerTab.Number
        playerTab.field.find((item) => {
          return item.attr.name === "Number";
        }).value = player.shirt_number;

        // end player Tab attributes
        // ***********************************************************
        // start formation Tab attributes
        // Formation Image (root)
        formationTab.value =
          "F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\" +
          awayTeam.name +
          "\\HOME\\STANDARD\\1.png"; // eg F:\\MEDIA\\FOOTBALL\\PLAYER_IMAGES\\NEWCASTLE\\HOME\\STANDARD\\1.png

        // FormationTab.active
        formationTab.field.find((item) => {
          return item.attr.name === "active";
        }).value = "1";

        // FormationTab.image_position
        formationTab.field.find((item) => {
          return item.attr.name === "image_position";
        }); //.value = '1';

        // FormationTab.image_scaling
        formationTab.field.find((item) => {
          return item.attr.name === "image_scaling";
        }); //.value = '1';

        let x =
          options.game.live_data.away_formation.positions[player.order - 1].x *
          4.5;
        let y =
          options.game.live_data.away_formation.positions[player.order - 1].y *
          2;
        // FormationTab.position
        let position = (formationTab.field.find((item) => {
          return item.attr.name === "position";
        }).value = x + "  " + y + " 0");
      });

    for (let i = 2; i <= 10; i++) {
      var nameTab = "4" + i + "0";
      var numTab = "4" + i + "1";

      if (i === 10) {
        nameTab = "495";
        numTab = "496";
      }
      console.log("away name", nameTab, i);
      try {
        xml.payload.field.find((item) => {
          return item.attr.name === numTab; // Number
        }).value = "";

        xml.payload.field.find((item) => {
          return item.attr.name === nameTab; //  name
        }).value = "";
      } catch (err) {
        debugger;
      }
    }

    // loop through subs (Players Right)
    players
      .filter((p) => p.order > 11)
      .forEach((player, index) => {
        // ** NOTE SCENE LIMITED TO 7 SUBS ***
        var nameTab = "4" + (index + 2) + "0";
        var numTab = "4" + (index + 2) + "1";

        // console.log(nameTab, "****", numTab);

        xml.payload.field.find((item) => {
          return item.attr.name === numTab; // Number
        }).value = player.shirt_number;

        xml.payload.field.find((item) => {
          return item.attr.name === nameTab; //  name
        }).value = player.player.last_name_upper;
      });
  }
}
