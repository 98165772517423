import moment from "moment";

export class Score {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Score";
    this.scene = "L3_MatchInfo";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    let homeTeam = options.teams.find(
      (team) => team.uuid === options.game.home_team
    );
    let awayTeam = options.teams.find(
      (team) => team.uuid === options.game.away_team
    );
    let scorer;

    if (options.show_scorers) {
      let scorers_sorted = [
        ...options.game.live_data.home_scorers.map((s) => {
          return { ...s, team: "home" };
        }),
        ...options.game.live_data.away_scorers.map((s) => {
          return { ...s, team: "away" };
        }),
      ];

      scorers_sorted = scorers_sorted.sort((a, b) => b.time - a.time);
      if (scorers_sorted.length > 0) {
        scorer = scorers_sorted[0];
      }
    }

    this.data = [
      {
        name: "BETFRED",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Sponsors/" + options.game.competition.logo || "blank.png"
            ),
        },
      },
      {
        name: "TOP_BAR_GRP",
        value: { visible: options.half == 4 && options.show_half },
      },
      {
        name: "TOP_BAR_TEXT",
        value: { text: "After Extra Time" },
      },
      {
        name: "Home Team Name",
        value: { text: options.game.home_team.variables["name_uppercase"] },
      },
      {
        name: "Away Team Name",
        value: { text: options.game.away_team.variables["name_uppercase"] },
      },
      {
        name: "LEFT_TEAM_COLOUR_1",
        value: {
          colour: options.game.home_team.variables[
            options.game?.home_team_kit + "_kit_hex_1"
          ]
            ? options.game.home_team.variables[
                options.game?.home_team_kit + "_kit_hex_1"
              ].replace("#", "0x")
            : "0x000000",
        },
      },
      {
        name: "LEFT_TEAM_COLOUR_2",
        value: {
          colour: options.game.home_team.variables[
            options.game?.home_team_kit + "_kit_hex_2"
          ]
            ? options.game.home_team.variables[
                options.game?.home_team_kit + "_kit_hex_2"
              ].replace("#", "0x")
            : "0x000000",
        },
      },
      {
        name: "RIGHT_TEAM_COLOUR_1",
        value: {
          colour: options.game.away_team.variables[
            options.game?.away_team_kit + "_kit_hex_1"
          ]
            ? options.game.away_team.variables[
                options.game?.away_team_kit + "_kit_hex_1"
              ].replace("#", "0x")
            : "0x000000",
        },
      },
      {
        name: "RIGHT_TEAM_COLOUR_2",
        value: {
          colour: options.game.away_team.variables[
            options.game?.away_team_kit + "_kit_hex_2"
          ]
            ? options.game.away_team.variables[
                options.game?.away_team_kit + "_kit_hex_2"
              ].replace("#", "0x")
            : "0x000000",
        },
      },
      { name: "STADIUM_TEXT", value: { text: options.game.stadium.name } },
      options.game.home_team.variables["badge_home"] && {
        name: "Home Badge IMG",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" + options.game.home_team.variables["badge_home"]
            ),
        },
      },
      options.game.away_team.variables["badge_away"] && {
        name: "Away Badge IMG",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" + options.game.away_team.variables["badge_away"]
            ),
        },
      },
      {
        name: "Home Score",
        value: {
          text:
            (options.game.live_data &&
              options.game.live_data.home_team_score) ||
            0,
        },
      },
      {
        name: "Away Score",
        value: {
          text:
            (options.game.live_data &&
              options.game.live_data.away_team_score) ||
            0,
        },
      },
      {
        name: "HALF_TEXT",
        value: { text: options.half == 1 ? "Half-Time" : "Full-Time" },
      },
      { name: "HALF_TIME", value: { visible: options.show_half || false } },
      {
        name: "HOME_SCORE_GRP",
        value: {
          visible:
            options.show_scorers && scorer && scorer.team === "home"
              ? true
              : false,
        },
      },
      {
        name: "AWAY_SCORE_GRP",
        value: {
          visible:
            options.show_scorers && scorer && scorer.team === "away"
              ? true
              : false,
        },
      },
    ];

    if (options.show_scorers && scorer) {
      let player = options.game.live_data.home_team_lineup.find(
        (l) => l._id === scorer.player
      );
      if (!player) {
        player = options.game.live_data.away_team_lineup.find(
          (l) => l._id === scorer.player
        );
      }
      let time = moment.duration(scorer.time);
      this.data.push({
        name: scorer.team.toUpperCase() + "_SCORER",
        value: {
          text:
            player.first_name[0] +
            ". " +
            player.last_name +
            " " +
            (time.minutes() + time.hours() * 60) +
            "' " +
            (scorer.type ? scorer.type : ""),
        },
      });
    }

    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
