import { Clock } from "./Clock";
import { MatchID } from "./MatchID";
import { Score } from "./Score";
import { Card } from "./Card";
import { Name } from "./Name";
import { Sub } from "./Sub";
import { SubMulti } from "./SubMulti";
import { FFFormation } from "./FFFormation";
import { CrawlFormation } from "./CrawlFormation";
import { Manager } from "./Manager";
import { Player } from "./Player";
import { TeamList } from "./TeamList";
import { PenaltyLT } from "./PenaltyLT";
import { ScorePenalties } from "./ScorePenalties";
import { Versus } from "./Versus";

export default function graphics({
  preview,
  animate,
  update,
  animateOff,
  triggerAnimation,
  clearPreview,
}) {
  return [
    new Clock({ preview, animate, update, animateOff, triggerAnimation }),
    new MatchID({ preview, animate, update, animateOff }),
    new Score({ preview, animate, update, animateOff }),
    new Card({ preview, animate, update, animateOff, triggerAnimation }),
    new Name({ preview, animate, update, animateOff }),
    new Manager({ preview, animate, update, animateOff }),
    new Sub({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new SubMulti({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      clearPreview,
    }),
    new FFFormation({ preview, animate, update, animateOff }),
    new CrawlFormation({ preview, animate, update, animateOff }),
    new Player({ preview, animate, update, animateOff }),
    new TeamList({ preview, animate, update, animateOff }),
    new PenaltyLT({ preview, animate, update, animateOff }),
    new ScorePenalties({ preview, animate, update, animateOff }),
    new Versus({ preview, animate, update, animateOff, triggerAnimation }),
  ];
}
