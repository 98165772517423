export class Name {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Name";
    this.scene = "L3_BadgeNumName";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [
      {
        name: "NUMBER",
        value: {
          text: options.player.shirt_number,
        },
      },
      {
        name: "FirstName",
        value: {
          text: options.player.first_name_upper,
        },
      },
      {
        name: "LastName",
        value: {
          text: options.player.last_name_upper,
        },
      },
    ];
    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
