export class Score {
  constructor({ viz }) {
    this.onAir = false;
    this.viz = viz;
    this.name = "Score";
    this.page = "7117";
    this.commsPage = "1401";
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
    this.options = options;
    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.game.home_team.opta_ID
    );
    let awaySky = options.sky.find(
      (sky) => sky.opta_ID === options.game.away_team.opta_ID
    );

    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "100"; // home Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.home_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // home Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\Alpha_Badges\\L3_Alpha_Badges\\" +
      options.game.home_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "120"; // away Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.away_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "121"; // away Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\Alpha_Badges\\L3_Alpha_Badges\\" +
      options.game.away_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "105"; // Score eg. 0-0
    }).value =
      options.game.live_data.home_team_score +
      "-" +
      options.game.live_data.away_team_score;

    if (options.half === 1 || options.half === 2) {
      let halfTextTab = xml.payload.field.find((item) => {
        return item.attr.name === "210"; // Score eg. 0-0
      });
      halfTextTab.value = options.half === 1 ? "HALF TIME" : "FULL TIME";
      halfTextTab.field.find((item) => {
        return item.attr.name === "active";
      }).value = "1";
      let ssLogoTab = xml.payload.field.find((item) => {
        return item.attr.name === "220"; // Score eg. 0-0
      });
      ssLogoTab.field.find((item) => {
        return item.attr.name === "active";
      }).value = "0";
    } else {
      let halfTextTab = xml.payload.field.find((item) => {
        return item.attr.name === "210"; // Score eg. 0-0
      });
      halfTextTab.field.find((item) => {
        return item.attr.name === "active";
      }).value = "0";
      let ssLogoTab = xml.payload.field.find((item) => {
        return item.attr.name === "220"; // Score eg. 0-0
      });
      ssLogoTab.field.find((item) => {
        return item.attr.name === "active";
      }).value = "1";
    }
    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }

  async previewCommentators() {
    let options = this.options;

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.game.home_team.opta_ID
    );
    let awaySky = options.sky.find(
      (sky) => sky.opta_ID === options.game.away_team.opta_ID
    );

    let xml = await this.viz.getPage(this.commsPage);

    xml.payload.field.find((item) => {
      return item.attr.name === "100"; // home Badge
    }).value = "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + homeSky.badge;

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // home Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\ALPHA_BADGES\\L3_ALPHA_BADGES\\" +
      homeSky.badge;

    xml.payload.field.find((item) => {
      return item.attr.name === "120"; // away Badge
    }).value = "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + awaySky.badge;

    xml.payload.field.find((item) => {
      return item.attr.name === "121"; // away Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\ALPHA_BADGES\\L3_ALPHA_BADGES\\" +
      awaySky.badge;

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // away Badge (alpha)
    }).value = "MARTIN TYLER &";

    xml.payload.field.find((item) => {
      return item.attr.name === "123"; // away Badge (alpha)
    }).value = "GARY NEVILLE";

    await this.viz.updateData(this.commsPage, xml);
    this.viz.preview(this.commsPage);
  }

  animate({ options }) {
    if (!this.onAir) {
      this.stage = 1;
      this.viz.take(this.page);
      //  this.previewCommentators({ options });
    } else {
      //this.stage = 2;
      // this.viz.take(this.commsPage);
    }
    this.onAir = true;
  }
  animateOff({ options }) {
    this.onAir = false;
    if (this.stage === 1) {
      this.viz.takeOut(this.page);
    } else {
      // this.viz.takeOut(this.commsPage);
    }
  }
}
