import moment from "moment";

export class PenaltyLT {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PenaltyLT";
    this.scene = "LT - Penalties";
    this.data = [];
  }

  preview({ options }) {
    let homeScore =
      options.game.live_data.home_penalties &&
      options.game.live_data.home_penalties.reduce((prev, curr) => {
        if (curr.type === 1) {
          return prev + 1;
        }
        return prev;
      }, 0);

    let awayScore =
      options.game.live_data.away_penalties &&
      options.game.live_data.away_penalties.reduce((prev, curr) => {
        if (curr.type === 1) {
          return prev + 1;
        }
        return prev;
      }, 0);
    this.data = [
      {
        name: "Home Team Name",
        value: { text: options.game.home_team.variables["name_uppercase"] },
      },
      {
        name: "Away Team Name",
        value: { text: options.game.away_team.variables["name_uppercase"] },
      },
      {
        name: "LEFT_TEAM_COLOUR_1",
        value: {
          colour: options.game.home_team.variables[
            options.game?.home_team_kit + "_kit_hex_1"
          ]
            ? options.game.home_team.variables["home_kit_hex_1"].replace(
                "#",
                "0x"
              )
            : "0x000000",
        },
      },
      {
        name: "LEFT_TEAM_COLOUR_2",
        value: {
          colour: options.game.home_team.variables[
            options.game?.home_team_kit + "_kit_hex_2"
          ]
            ? options.game.home_team.variables[
                options.game?.home_team_kit + "_kit_hex_2"
              ].replace("#", "0x")
            : "0x000000",
        },
      },
      {
        name: "RIGHT_TEAM_COLOUR_1",
        value: {
          colour: options.game.away_team.variables[
            options.game?.away_team_kit + "_kit_hex_2"
          ]
            ? options.game.away_team.variables[
                options.game?.away_team_kit + "_kit_hex_2"
              ].replace("#", "0x")
            : "0x000000",
        },
      },
      {
        name: "RIGHT_TEAM_COLOUR_2",
        value: {
          colour: options.game.away_team.variables[
            options.game?.away_team_kit + "_kit_hex_2"
          ]
            ? options.game.away_team.variables[
                options.game?.away_team_kit + "_kit_hex_2"
              ].replace("#", "0x")
            : "0x000000",
        },
      },
      { name: "STADIUM_TEXT", value: { text: options.game.stadium.name } },
      options.game.home_team.variables["badge_home"] && {
        name: "Home Badge IMG",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" + options.game.home_team.variables["badge_home"]
            ),
        },
      },
      options.game.away_team.variables["badge_away"] && {
        name: "Away Badge IMG",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" + options.game.away_team.variables["badge_away"]
            ),
        },
      },
      {
        name: "Home Score",
        value: {
          text: homeScore || 0,
        },
      },
      {
        name: "Away Score",
        value: {
          text: awayScore || 0,
        },
      },
      {
        name: "HALF_TEXT",
        value: { text: options.half == 1 ? "Half-Time" : "Full-Time" },
      },
      { name: "HALF_TIME", value: { visible: options.show_half || false } },
    ];

    new Array(5).fill(1).map((item, i) => {
      let index = i;

      if (
        options.game.live_data.home_penalties &&
        options.game.live_data.home_penalties.filter(
          (p) => p.type == 0 || p.type == 1
        ).length > 5
      ) {
        index += 5;
      }
      if (
        options.game.live_data.home_penalties &&
        options.game.live_data.home_penalties.filter(
          (p) => p.type == 0 || p.type == 1
        ).length > 10
      ) {
        index += 5;
      }
      let pen =
        options.game.live_data.home_penalties &&
        options.game.live_data.home_penalties.find((p) => p.index === index);

      this.data.push({
        name: "HOME-BLANK_" + (i + 1),
        value: {
          visible: !pen || (pen.type !== 0 && pen.type !== 1) ? true : false,
        },
      });
      this.data.push({
        name: "HOME-RED_" + (i + 1),
        value: { visible: pen && pen.type === 0 ? true : false },
      });
      this.data.push({
        name: "HOME-GREEN_" + (i + 1),
        value: { visible: pen && pen.type === 1 ? true : false },
      });
    });

    new Array(5).fill(1).map((item, i) => {
      let index = i;

      if (
        options.game.live_data.home_penalties &&
        options.game.live_data.home_penalties.filter(
          (p) => p.type == 0 || p.type == 1
        ).length > 5
      ) {
        index += 5;
      }
      if (
        options.game.live_data.home_penalties &&
        options.game.live_data.home_penalties.filter(
          (p) => p.type == 0 || p.type == 1
        ).length > 10
      ) {
        index += 5;
      }
      let pen =
        options.game.live_data.away_penalties &&
        options.game.live_data.away_penalties.find((p) => p.index === index);

      this.data.push({
        name: "AWAY-BLANK_" + (i + 1),
        value: {
          visible: !pen || (pen.type !== 0 && pen.type !== 1) ? true : false,
        },
      });
      this.data.push({
        name: "AWAY-RED_" + (i + 1),
        value: { visible: pen && pen.type === 0 ? true : false },
      });
      this.data.push({
        name: "AWAY-GREEN_" + (i + 1),
        value: { visible: pen && pen.type === 1 ? true : false },
      });
    });
    if (this.onAir) {
      this.sendUpdate(this.scene, this.data);
    } else {
      this.sendPreview(this.scene, 50, this.data, "IN");
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
