export class TeamStat {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "TeamStat";
    this.page = "7141";
    this.data = [];
    this.viz = viz;
  }

  async preview({ options }) {
    this.onAir = false;

    let xml = await this.viz.getPage(this.page);

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.game.home_team.opta_ID
    );
    let awaySky = options.sky.find(
      (sky) => sky.opta_ID === options.game.away_team.opta_ID
    );

    let homeKits = options.sky.find(
      (sky) => sky.opta_ID === options.game.home_team.opta_ID
    );
    let homeKit = options.game.live_data.home_kit;
    let awayKits = options.sky.find(
      (sky) => sky.opta_ID === options.game.away_team.opta_ID
    );
    let awayKit = options.game.live_data.away_kit;
    let homeBGColour = options.game.home_team.variables["home_kit_bg"];
    let awayBGColour = options.game.away_team.variables["home_kit_bg"];
    let homeTextColour = options.game.home_team.variables["home_kit_text"];
    let awaytextColour = options.game.away_team.variables["home_kit_text"];

    xml.payload.field.find((item) => {
      return item.attr.name === "0123"; // away team back colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" + homeBGColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "0124"; // away team back colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" +
      homeTextColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "0223"; // away team back colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" + awayBGColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "0224"; // away team back colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" +
      awaytextColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "002"; // Team Stat type eg Corners
    }).value = options.stat.category;
    xml.payload.field.find((item) => {
      return item.attr.name === "0121"; // home team
    }).value = options.homeTeam.code;
    xml.payload.field.find((item) => {
      return item.attr.name === "0122"; // home value
    }).value = options.stat.home;
    xml.payload.field.find((item) => {
      return item.attr.name === "0221"; // away team
    }).value = options.awayTeam.code;
    xml.payload.field.find((item) => {
      return item.attr.name === "0222"; // away value
    }).value = options.stat.away;

    let sponsorTab = xml.payload.field.find((item) => {
      return item.attr.name === "SponsorLogo"; // away value
    });

    sponsorTab.field.find((item) => {
      return item.attr.name === "active";
    }).value = options.showEA;

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
