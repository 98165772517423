import React, { useRef, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
`;
const StatDiv = styled.div`
  display: flex;
`;
const Category = styled.div`
  display: flex;
  min-width: 60%;
  justify-content: center;
  padding: 0.5em;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 2px;
  align-items: center;
  font-size: ${(props) => (props.small ? ".8em" : "")};
  :hover {
    background-color: #db0a41;
  }
`;
const Bar = styled.div`
  display: flex;
  width: 100%;
`;
const InnerBar = styled.div`
  display: flex;
`;
const Bars = styled.div`
  display: flex;
  width: 100%;
`;

const usePrevious = function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default function Stat({ category, stat, game }) {
  const { previewGraphic } = useContext(GraphicsContext);
  return (
    <Main>
      <StatDiv>
        <Value
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {(stat && category !== "Possession" && stat.home) || 0}
          {stat &&
            category === "Possession" &&
            Math.round(stat.home || 0) + "%"}
        </Value>
        <Category
          onClick={() => {
            let new_stat = { ...stat };

            new_stat.home = category !== "Possession" ? stat.home : 0;
            new_stat.home =
              category === "Possession"
                ? Math.round(stat.home || 0) + "%"
                : new_stat.home;
            new_stat.away = category !== "Possession" ? stat.away : 0;
            new_stat.away =
              category === "Possession"
                ? Math.round(stat.away || 0) + "%"
                : new_stat.away;
            previewGraphic("TeamStat", {
              category,
              stat: new_stat,
              game,
            });
          }}
        >
          {category}
        </Category>
        <Value
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {(stat && category !== "Possession" && stat.away) || 0}
          {stat && category === "Possession" && Math.round(stat.away) + "%"}
        </Value>
      </StatDiv>
      <Bars>
        <Bar style={{ justifyContent: "flex-end" }}>
          <InnerBar />
        </Bar>
        <Bar>
          <InnerBar />
        </Bar>
      </Bars>
    </Main>
  );
}
