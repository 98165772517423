export class Formations {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "Formations";
    this.page = "7123";
    this.viz = viz;
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = options;
    let xml = await this.viz.getPage(this.page);

    this.build(xml, options);

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }

  build(xml, options) {
    let players = options.lineup;

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.team.opta_ID
    );

    xml.payload.field.find((item) => {
      return item.attr.name === "000"; // formation
    }).value = options.formation.name;

    xml.payload.field.find((item) => {
      return item.attr.name === "005"; // Team Name
    }).value = options.team.name;

    xml.payload.field.find((item) => {
      return item.attr.name === "400"; // Team Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + options.team.variables["badge"];

    let manager = options.manager;

    xml.payload.field.find((item) => {
      return item.attr.name === "4005"; // Manager Title
    }).value = "Manager";

    xml.payload.field.find((item) => {
      return item.attr.name === "401"; // Manager first name
    }).value = manager.first_name;

    xml.payload.field.find((item) => {
      return item.attr.name === "402"; // Manager Surname
    }).value = manager.last_name;

    let scaleY = 2;
    let scaleX = 4.5;
    // loop through starting 11 (Players left)
    players
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order <= 11)
      .forEach((player, index) => {
        var plinthTab = index + 1 + "0";
        var numTab = index + 1 + "1";
        var lastTab = index + 1 + "2";
        var imageTab = index + 1 + "3";

        xml.payload.field
          .find((item) => {
            return item.attr.name === plinthTab.padStart(3, "0"); // Number
          })
          .field.find((pos) => {
            //X  Y  Z
            return pos.attr.name === "position"; // X Y Z  eg. for goalie at the top of frame '0 171 0'
          }).value =
          options.formation.positions[index].x * scaleX +
          " " +
          options.formation.positions[index].y * scaleY +
          " 0";

        xml.payload.field.find((item) => {
          return item.attr.name === numTab.padStart(3, "0"); // Number
        }).value = player.shirt_number;

        // playerTab.Surname
        xml.payload.field.find((item) => {
          return item.attr.name === lastTab.padStart(3, "0"); // surname
        }).value =
          player.last_name_upper + "    " + (player.captain ? "(C)" : "");
      });

    // loop through subs (Players Right)
    players
      .filter((p) => p.order > 11)
      .forEach((player, index) => {
        var nameTab = "6" + (index + 1) + "2";
        var numTab = "6" + (index + 1) + "1";
        xml.payload.field.find((item) => {
          return item.attr.name === numTab; // Number
        }).value = player.shirt_number;

        xml.payload.field.find((item) => {
          return item.attr.name === nameTab; //  name
        }).value =
          player.last_name_upper + "    " + (player.captain ? "(C)" : "");
      });
  }
  animate({ options }) {
    this.onAir = true;
    this.viz.take(this.page);
    options = { ...this.data, team: 2 };

    this.preview({ options: options });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
