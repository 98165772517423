export class Tag {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "Tag";
    this.downloadPage = "7105";
    this.page = "7105"; // increases for each goal eg 1st goal = 1251. 2nd = 1252, 3rd = 1253 etc
    this.data = [];
    this.viz = viz;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // home team
    }).value = options.player.first_name + " " + options.player.last_name; // needs to be {goalScorer},'goal',{goalTime},'min/mins')

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // home score
    }).value = "";

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
