export class SubMulti {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    triggerAnimation,
    clearPreview,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.clearPreview = clearPreview;
    this.name = "SubstituteMulti";
    this.scene = "CLOCK_SUB_1";
    this.scene_2 = "CLOCK_SUB_2";
    this.scene_3 = "CLOCK_SUB_3";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.team =
      options.team._id === options.game.home_team._id ? "home" : "away";

    if (options.subs.length === 2) {
      this.scene = this.scene_2;
      this.data = [
        { name: "Home Team", value: { text: options.game.home_team.code } },
        { name: "Away Team", value: { text: options.game.away_team.code } },
        {
          name: "HOME_TEAM",
          value: {
            visible: this.team === "home" ? true : false,
          },
        },
        {
          name: "AWAY_TEAM",
          value: {
            visible: this.team === "away" ? true : false,
          },
        },
        {
          name: "NAME",
          value: {
            text: options.subs[0].players[0].last_name_upper,
          },
        },
        {
          name: "NUMBER",
          value: {
            text: options.subs[0].players[0].shirt_number,
          },
        },
        {
          name: "NAME_2",
          value: {
            text: options.subs[1].players[0].last_name_upper,
          },
        },
        {
          name: "NUMBER_2",
          value: {
            text: options.subs[1].players[0].shirt_number,
          },
        },
        {
          name: "NAME_3",
          value: {
            text: options.subs[0].players[1].last_name_upper,
          },
        },
        {
          name: "NUMBER_3",
          value: {
            text: options.subs[0].players[1].shirt_number,
          },
        },
        {
          name: "NAME_4",
          value: {
            text: options.subs[1].players[1].last_name_upper,
          },
        },
        {
          name: "NUMBER_4",
          value: {
            text: options.subs[1].players[1].shirt_number,
          },
        },
      ];
    } else if (options.subs.length === 3) {
      this.scene = this.scene_3;
      this.data = [
        { name: "Home Team", value: { text: options.game.home_team.code } },
        { name: "Away Team", value: { text: options.game.away_team.code } },
        {
          name: "HOME_TEAM",
          value: {
            visible: this.team === "home" ? true : false,
          },
        },
        {
          name: "AWAY_TEAM",
          value: {
            visible: this.team === "away" ? true : false,
          },
        },
        {
          name: "NAME",
          value: {
            text: options.subs[0].players[0].last_name_upper,
          },
        },
        {
          name: "NUMBER",
          value: {
            text: options.subs[0].players[0].shirt_number,
          },
        },
        {
          name: "NAME_2",
          value: {
            text: options.subs[1].players[0].last_name_upper,
          },
        },
        {
          name: "NUMBER_2",
          value: {
            text: options.subs[1].players[0].shirt_number,
          },
        },
        {
          name: "NAME_3",
          value: {
            text: options.subs[2].players[0].last_name_upper,
          },
        },
        {
          name: "NUMBER_3",
          value: {
            text: options.subs[2].players[0].shirt_number,
          },
        },
        {
          name: "NAME_4",
          value: {
            text: options.subs[0].players[1].last_name_upper,
          },
        },
        {
          name: "NUMBER_4",
          value: {
            text: options.subs[0].players[1].shirt_number,
          },
        },
        {
          name: "NAME_5",
          value: {
            text: options.subs[1].players[1].last_name_upper,
          },
        },
        {
          name: "NUMBER_5",
          value: {
            text: options.subs[1].players[1].shirt_number,
          },
        },
        {
          name: "NAME_6",
          value: {
            text: options.subs[2].players[1].last_name_upper,
          },
        },
        {
          name: "NUMBER_6",
          value: {
            text: options.subs[2].players[1].shirt_number,
          },
        },
      ];
    }

    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    if (this.onAir) {
      this.triggerAnimation(this.scene, this.data, "T3");
    } else {
      this.sendAnimate(this.scene, this.data);
      this.sendPreview(this.scene, 50, this.data, "T3");
    }
    this.onAir = true;
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
    this.clearPreview(this.name);
  }
}
