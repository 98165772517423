import parser from "fast-xml-parser";
var options = {
  attributeNamePrefix: "",
  attrNodeName: "attr", //default is 'false'
  textNodeName: "#text",
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: true,
  parseNodeValue: false,
  parseAttributeValue: false,
  trimValues: false,
  cdataTagName: "__cdata", //default is 'false'
  cdataPositionChar: "\\c",
  parseTrueNumberOnly: false,
  arrayMode: false, //"strict"
  stopNodes: ["parse-me-as-string"],
};
var XMLParser = parser.j2xParser;
const toXML = new XMLParser(options);

export class Viz {
  constructor(showName) {
    window.MSEAddress =
      window.localStorage.getItem("MSEAddress") || "127.0.0.1";
    window.hyperLink = window.localStorage.getItem("hyperLink") || "127.0.0.1";
    window.showName =
      window.localStorage.getItem("showName") || "SkySports_Football";
    window.vizProfile = window.localStorage.getItem("vizProfile") || "default";
    window.vizLog = window.localStorage.getItem("vizLog") || "[]";
    if (window.vizLog) {
      window.vizLog = JSON.parse(window.vizLog);
    }

    // console.log("in viz");
    this.server = "http://" + window.MSEAddress + ":8580/";
    this.getShow();
    setInterval(() => {
      this.getShow();
    }, 5000);
    this.connectTrio();
  }

  connectTrio() {
    let timeout;
    this.trio = new WebSocket("ws://" + window.hyperLink + ":7070");

    this.trio.onopen = () => {
      console.log("Hyper Link connected");
    };
    this.trio.onmessage = (data) => {};
    this.trio.onerror = (err) => {
      console.log("Hyper Link Error");
      this.trio.close();
    };
    this.trio.onclose = (data) => {
      console.log("Hyper Link disconnected");
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.connectTrio();
      }, 1000);
    };
  }

  getShow() {
    fetch("http://" + window.MSEAddress + ":8580/" + "directory/shows/").then(
      (data) => {
        data.text().then((data) => {
          var jsonObj = parser.parse(data, options);

          this.show = jsonObj.feed.entry.find(
            (element) => element.title === window.showName
          );
          // console.log(this.show);
          this.showID = this.show.link[0].attr.href.split("/").slice(-2)[0];
          // console.log(this.showID);
          //this.update(9910)
          //this.take(9910);
          //setTimeout(() => this.takeOut(9910), 5000);
        });
      }
    );
  }
  sendCommand(command) {
    window.vizLog.push({ time: Date.now(), type: "Trio", command: command });
    window.localStorage.setItem("vizLog", JSON.stringify(window.vizLog));
    this.trio.send(command);
  }
  preview(page) {
    window.vizLog.push({
      time: Date.now(),
      type: "Trio",
      command: "page:read " + page,
    });
    window.localStorage.setItem("vizLog", JSON.stringify(window.vizLog));
    this.trio.send("page:read " + page);
  }
  take(page) {
    let pageUrl =
      "http://" +
      window.MSEAddress +
      ":8580/element/storage/shows/" +
      this.showID +
      "/elements/" +
      page;
    fetch(
      "http://" +
        window.MSEAddress +
        ":8580/profiles/" +
        window.vizProfile +
        "/take",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: pageUrl,
      }
    ).then((data) => {
      window.vizLog.push({
        time: Date.now(),
        type: "MSE",
        command: "TAKE: " + pageUrl,
      });
      window.localStorage.setItem("vizLog", JSON.stringify(window.vizLog));
    });
  }

  takeOut(page) {
    fetch(
      "http://" +
        window.MSEAddress +
        ":8580/profiles/" +
        window.vizProfile +
        "/out",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body:
          "http://" +
          window.MSEAddress +
          ":8580/element/storage/shows/" +
          this.showID +
          "/elements/" +
          page,
      }
    ).then((data) => {});
  }

  continue(page) {
    fetch(
      "http://" +
        window.MSEAddress +
        ":8580/profiles/" +
        window.vizProfile +
        "/continue",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body:
          "http://" +
          window.MSEAddress +
          ":8580/element/storage/shows/" +
          this.showID +
          "/elements/" +
          page,
      }
    ).then((data) => {});
  }

  async update(page) {
    let pageUrl =
      "http://" +
      window.MSEAddress +
      ":8580/element/storage/shows/" +
      this.showID +
      "/elements/" +
      page;
    await fetch(
      "http://" +
        window.MSEAddress +
        ":8580/profiles/" +
        window.vizProfile +
        "/update",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: pageUrl,
      }
    ).then((data) => {
      window.vizLog.push({
        time: Date.now(),
        type: "MSE",
        command: "UPDATE: " + pageUrl,
      });
      window.localStorage.setItem("vizLog", JSON.stringify(window.vizLog));

      console.log("UPDATED PAGE", page);
      console.log(data);
    });
  }

  getPage(page) {
    console.log(
      "http://" +
        window.MSEAddress +
        ":8580/element/storage/shows/" +
        this.showID +
        "/elements/" +
        page
    );
    return fetch(
      "http://" +
        window.MSEAddress +
        ":8580/element/storage/shows/" +
        this.showID +
        "/elements/" +
        page
    ).then((data) =>
      data.text().then((data) => {
        return parser.parse(data, options);
      })
    );
  }

  async updateData(page, data) {
    let xml = toXML.parse(data);
    xml = xml.replace(/&/g, "&amp;");
    // console.log(xml)

    await fetch(
      "http://" +
        window.MSEAddress +
        ":8580/element/storage/shows/" +
        this.showID +
        "/elements/" +
        page,
      {
        method: "PUT",
        headers: {
          "content-type": "Application/vnd.vizrt.payload+xml",
        },
        body: xml,
      }
    ).then((data) =>
      data.text().then((data) => {
        window.vizLog.push({
          time: Date.now(),
          type: "MSE",
          url:
            "http://" +
            window.MSEAddress +
            ":8580/element/storage/shows/" +
            this.showID +
            "/elements/" +
            page,
          command: xml,
        });
        window.localStorage.setItem("vizLog", JSON.stringify(window.vizLog));
        //console.log(data)
      })
    );
  }
}
