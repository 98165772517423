import React from "react";
import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const SubIcon = styled(FaChevronDown)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  color: #db0a41;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 1.2em;
  align-items: center;
`;

export default function Opta18({ item, player }) {
  return (
    <Main>
      <Title>
        <SubIcon />
        Player Off
      </Title>
      <Content>
        {player?.squad?.shirt_number}. {player?.squad?.player?.last_name}
      </Content>
    </Main>
  );
}
