export class GoalTag {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "GoalTag";
    this.downloadPage = "7132";
    this.page = "1251"; // increases for each goal eg 1st goal = 1251. 2nd = 1252, 3rd = 1253 etc
    this.data = [];
    this.viz = viz;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.viz.getPage(this.page);
    let goalTime = "";
    if (options.time > 0) {
      goalTime = options.time + (options.time > 1 ? " mins" : " min");
    } else {
      goalTime = options.seconds + " seconds";
    }
    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // home team
    }).value = options.player.last_name + " goal     " + goalTime; // needs to be {goalScorer},'goal',{goalTime},'min/mins')

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // home score
    }).value =
      options.goal.home_team_score + "-" + options.goal.away_team_score;

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
