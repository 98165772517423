export class SubstituteMulti {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "SubstituteMulti";
    this.page = "7111";
    this.data = [];
    this.viz = viz;
  }

  async preview({ options }) {
    this.onAir = false;
    switch (options.subs.length) {
      case 2:
        if (this.home) {
          this.page = 7111;
        } else {
          // away
          this.page = 7161;
        }
        break;
      case 3:
        if (this.home) {
          this.page = 7112;
        } else {
          // away
          this.page = 7162;
        }
        break;
    }

    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "0004"; // No. of subtext lines
    }).value = "1";

    let sky = options.sky.find(
      (sky) => sky.opta_ID === options.subs[0].players[0].team.opta_ID
    );

    xml.payload.field.find((item) => {
      return item.attr.name === "100"; // Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + options.team.variables["badge"];

    options.subs.forEach((sub, index) => {
      sub.players = sub.players.sort((a, b) => a.order - b.order);

      // for each substitution
      //off
      xml.payload.field.find((item) => {
        return item.attr.name === "10" + ((index + 1) * 2 - 1); // off Number
      }).value = sub.players[0].shirt_number;

      xml.payload.field.find((item) => {
        return item.attr.name === "10" + (index + 1) * 2; // off surname
      }).value = sub.players[0].last_name_upper;

      //on
      xml.payload.field.find((item) => {
        return item.attr.name === "20" + ((index + 1) * 2 - 1); // on Number
      }).value = sub.players[1].shirt_number;

      xml.payload.field.find((item) => {
        return item.attr.name === "20" + (index + 1) * 2; // on surname
      }).value = sub.players[1].last_name_upper;
    });

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    if (this.onAir) {
      this.viz.continue(this.page);
    } else {
      this.viz.take(this.page);
    }
    this.onAir = true;
  }
  animateOff({ options }) {
    this.viz.takeOut(this.page);
  }
}
