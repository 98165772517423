import React, { useContext } from "react";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const CardIcon = styled.div`
  position: absolute;
  left: 3px;
  width: 10px;
  height: 18px;
  background-color: ${(props) => (props.red ? "#db0a41" : "#fffe00")};
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 1.2em;
  align-items: center;
`;
export default function Opta17({ item, player, previewGraphic, team, game }) {
  let redCard =
    item.qualifier.find((item) => item.qualifierId === 33) !== undefined;
  let yellowCard =
    item.qualifier.find((item) => item.qualifierId === 31) !== undefined;
  let doubleYellow =
    item.qualifier.find((item) => item.qualifierId === 32) !== undefined;
  let type = "";
  if (redCard) {
    type = 3;
  }
  if (yellowCard) {
    type = 1;
  }
  if (doubleYellow) {
    type = 2;
  }

  return (
    <Main
      onClick={() => {
        previewGraphic("Card", {
          player: { ...player?.squad, ...player?.squad?.player },
          cardType: type,
          team,
          game,
        });
      }}
    >
      <Title>
        <CardIcon red={type === 3} />
        {type === 2 && <CardIcon />}
        Card
      </Title>
      <Content>
        {player?.squad?.shirt_number}. {player?.squad?.player?.last_name}
      </Content>
    </Main>
  );
}
