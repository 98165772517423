export class FormationSOF {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "FormationSOF";
    this.page = "7500";
    this.viz = viz;
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = options;
    let xml = await this.viz.getPage(this.page);

    this.build(xml, options);

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }

  build(xml, options) {
    let players = options.lineup;

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.team.opta_ID
    );

    xml.payload.field.find((item) => {
      return item.attr.name === "005"; // Team Name
    }).value = options.team.short_name;

    xml.payload.field.find((item) => {
      return item.attr.name === "400"; // Team Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + options.team.variables["badge"];

    // loop through starting 11 (Players left)
    players
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order <= 11)
      .forEach((player, index) => {
        xml.payload.field.find((item) => {
          return (
            item.attr.name === player.order.toString().padStart(2, "0") + "1"
          ); // Number
        }).value = player.shirt_number;

        // playerTab.Surname
        xml.payload.field.find((item) => {
          return (
            item.attr.name === player.order.toString().padStart(2, "0") + "2"
          ); // Number
        }).value = player.first_name_upper;
        xml.payload.field.find((item) => {
          return (
            item.attr.name === player.order.toString().padStart(2, "0") + "21"
          ); // Number
        }).value = player.last_name_upper;
      });

    // loop through subs (Players Right)
    players
      .filter((p) => p.order > 11)
      .forEach((player, index) => {
        xml.payload.field.find((item) => {
          return item.attr.name === "16" + (index + 1) + "2"; // Number
        }).value = player.shirt_number;

        // playerTab.Surname
        xml.payload.field.find((item) => {
          return item.attr.name === "16" + (index + 1) + "1"; // Number
        }).value = player.first_name_upper;
        xml.payload.field.find((item) => {
          return item.attr.name === "16" + (index + 1); // Number
        }).value = player.last_name_upper;
      });
  }
  animate({ options }) {
    this.onAir = true;
    this.viz.take(this.page);
    options = { ...this.data, team: 2 };

    this.preview({ options: options });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
