export class MatchID {
  constructor({ viz }) {
    this.onAir = false;
    this.viz = viz;
    this.name = "MatchID";
    this.page = "1400";
    this.commsPage = "1401";
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
    this.options = options;

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.game.home_team.opta_ID
    );
    let awaySky = options.sky.find(
      (sky) => sky.opta_ID === options.game.away_team.opta_ID
    );

    let stadium = options.game.stadium;

    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "100"; // home Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.home_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // home Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\ALPHA_BADGES\\L3_ALPHA_BADGES\\" +
      options.game.home_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "120"; // away Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.away_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "121"; // away Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\ALPHA_BADGES\\L3_ALPHA_BADGES\\" +
      options.game.away_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "201"; // Caption
    }).value = stadium.name.toUpperCase();

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }

  async previewCommentators() {
    let options = this.options;

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.game.home_team.opta_ID
    );
    let awaySky = options.sky.find(
      (sky) => sky.opta_ID === options.game.away_team.opta_ID
    );

    let xml = await this.viz.getPage(this.commsPage);

    xml.payload.field.find((item) => {
      return item.attr.name === "100"; // home Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.home_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // home Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\ALPHA_BADGES\\L3_ALPHA_BADGES\\" +
      options.game.home_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "120"; // away Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
      options.game.away_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "121"; // away Badge (alpha)
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\ALPHA_BADGES\\L3_ALPHA_BADGES\\" +
      options.game.away_team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // away Badge (alpha)
    }).value = "MARTIN TYLER &";

    xml.payload.field.find((item) => {
      return item.attr.name === "123"; // away Badge (alpha)
    }).value = "GARY NEVILLE";

    await this.viz.updateData(this.commsPage, xml);
    this.viz.preview(this.commsPage);
  }

  animate({ options }) {
    if (!this.onAir) {
      this.stage = 1;
      this.viz.take(this.page);
      this.previewCommentators({ options });
    } else {
      this.stage = 2;
      this.viz.take(this.commsPage);
    }
    this.onAir = true;
  }
  animateOff({ options }) {
    this.onAir = false;
    if (this.stage === 1) {
      this.viz.takeOut(this.page);
    } else {
      this.viz.takeOut(this.commsPage);
    }
  }
}
