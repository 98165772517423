import { Viz } from "../../services/viz";
import { Name } from "./Name";
import { Card } from "./Card";
import { Clock } from "./Clock";
import { MatchID } from "./MatchID";
import { Score } from "./Score";
import { TeamStat } from "./TeamStat";
import { Standings } from "./Standings";
import { HeroFormation } from "./HeroFormation";
import { CrawlFormation } from "./CrawlFormation";
import { Tag } from "./Tag";
import { Substitute } from "./Substitute";
import { SubstituteMulti } from "./SubstituteMulti";
import { Formations } from "./Formations";
import { FormationSOF } from "./FormationSOF";
import { FormationFF } from "./FormationFF";
import { GoalTag } from "./GoalTag";

export default function graphics(viz) {
  return [
    new Name({ viz }),
    new Card({ viz }),
    new Clock({ viz }),
    new MatchID({ viz }),
    new Score({ viz }),
    new TeamStat({ viz }),
    new Standings({ viz }),
    new Formations({ viz }),
    new HeroFormation({ viz }),
    new CrawlFormation({ viz }),
    new Tag({ viz }),
    new Substitute({ viz }),
    new SubstituteMulti({ viz }),
    new FormationSOF({ viz }),
    new FormationFF({ viz }),
    new GoalTag({ viz }),
  ];
}
