import React from "react";
import styled from "styled-components";
import { GiSoccerBall } from "react-icons/gi";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const FootballIcon = styled(GiSoccerBall)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 1.2em;
  align-items: center;
`;

export default function Opta16({ item, player, previewGraphic, game }) {
  return (
    <Main
      onClick={() => {
        previewGraphic("Score", {
          game: game,
          half: 2,
          show_half: false,
        });
      }}
    >
      <Title>
        <FootballIcon />
        Goal
      </Title>
      <Content>
        {player?.squad?.shirt_number}. {player?.squad?.player?.last_name}
      </Content>
    </Main>
  );
}
