export class FormationFF {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "FormationFF";
    this.page = "7121";
    this.viz = viz;
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = options;
    let xml = await this.viz.getPage(this.page);

    this.build(xml, options);

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }

  build(xml, options) {
    let players = options.lineup;

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === options.team.opta_ID
    );

    xml.payload.field.find((item) => {
      return item.attr.name === "005"; // Team Name
    }).value = options.team.variables["name_uppercase"];

    xml.payload.field.find((item) => {
      return item.attr.name === "400"; // Team Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + options.team.variables["badge"];

    let manager = options.manager;

    xml.payload.field.find((item) => {
      return item.attr.name === "150"; // Manager Title
    }).value = "Manager";

    xml.payload.field.find((item) => {
      return item.attr.name === "151"; // Manager first name
    }).value = manager.first_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "1511"; // Manager Surname
    }).value = manager.last_name_upper;

    let formations = ("1" + options.formation.name).split("");
    let formationIndex = 0;

    // loop through starting 11 (Players left)
    players
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order <= 11)
      .forEach((player, index) => {
        xml.payload.field.find((item) => {
          return (
            item.attr.name === player.order.toString().padStart(2, "0") + "1"
          ); // Number
        }).value = player.shirt_number;

        // playerTab.Surname
        xml.payload.field.find((item) => {
          return (
            item.attr.name === player.order.toString().padStart(2, "0") + "2"
          ); // Number
        }).value = player.first_name_upper;
        xml.payload.field.find((item) => {
          return (
            item.attr.name === player.order.toString().padStart(2, "0") + "21"
          ); // Number
        }).value = player.last_name_upper;

        let offset = "0";

        if (formations[formationIndex] == index + 1) {
          formationIndex++;
          formations[formationIndex] =
            parseInt(formations[formationIndex]) + index + 1;
          offset = "1";
        }
        if (player.order < 11) {
          xml.payload.field.find((item) => {
            return (
              item.attr.name === player.order.toString().padStart(2, "0") + "3"
            ); // Offset
          }).value = offset;
        }
      });

    // loop through subs (Players Right)
    players
      .filter((p) => p.order > 11)
      .forEach((player, index) => {
        xml.payload.field.find((item) => {
          return item.attr.name === "16" + (index + 1) + "2"; // Number
        }).value = player.shirt_number;

        // playerTab.Surname
        xml.payload.field.find((item) => {
          return item.attr.name === "16" + (index + 1); // Number
        }).value = player.first_name + " " + player.last_name;
      });

    xml.payload.field.find((item) => {
      return item.attr.name === "004"; // SUBS AMOUNT 0 = 7; 1=9
    }).value = players.filter((p) => p.order > 11).length <= 7 ? "0" : "1";
  }
  animate({ options }) {
    this.onAir = true;
    this.viz.take(this.page);
    options = { ...this.data, team: 2 };

    this.preview({ options: options });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
