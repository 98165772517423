export class TeamStat {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamStat";
    this.scene = "Team Stat";
    this.data = [];
  }

  preview({ options }) {
    debugger;
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Category",
      value: {
        text: options.category || "",
      },
    });
    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Value",
      value: {
        text: options.stat?.home || "0",
      },
    });
    this.data.push({
      name: "Away Team Value",
      value: {
        text: options.stat?.away || "0",
      },
    });

    this.data.push({
      name: "Home Team Name",
      value: {
        text: options.game?.home_team?.name || "",
      },
    });

    this.data.push({
      name: "Home Team Code",
      value: {
        text: options.game?.home_team?.code || "",
      },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Name",
      value: {
        text: options.game?.away_team?.name || "",
      },
    });

    this.data.push({
      name: "Away Team Code",
      value: {
        text: options.game?.away_team?.code || "",
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
