import moment from "moment";

export class Clock {
  constructor({ viz }) {
    this.onAir = false;
    this.time = 0;
    this.viz = viz;
    this.name = "CLOCK";
    this.page = "8090";
    this.data = [];
    this.addedTime = true;
    this.game = null;
    this.clockStarted = false;
    this.shownAddedTime = false;
    this.shownET = false;
    this.sponsorTimes = [
      {
        minute: 0,
        period: 1,
        shown: false,
      },

      {
        minute: 15,
        period: 1,
        shown: false,
      },
      {
        minute: 30,
        period: 1,
        shown: false,
      },
      {
        minute: 45,
        period: 2,
        shown: false,
      },
      {
        minute: 60,
        period: 2,
        shown: false,
      },
      {
        minute: 75,
        period: 2,
        shown: false,
      },
    ];

    this.blmLogo = [{ period: 1, minute: 8, seconds: 46, shown: false }];
    this.interval = setInterval(() => {
      this.updateClock();
    }, 500);
  }

  async preview({ options }) {
    // if (options.kickoff) {
    //   this.startClock();
    // }
    this.onAir = false;
    this.onAir = true;
  }

  async animate({ options }) {
    setTimeout(() => {
      this.onAir = true;
    }, 1000);
    await this.updateData({ options });
    this.viz.take(this.page);
  }

  async updateData({ options }) {
    let period = options.period;

    if (this.kick_off !== period.kick_off) {
      this.clockStarted = false;
    }
    this.game = options.game;
    this.showTime = options.showTime;

    let homeSky = options.sky.find(
      (sky) => sky.opta_ID === this.game.home_team.opta_ID
    );
    let awaySky = options.sky.find(
      (sky) => sky.opta_ID === this.game.away_team.opta_ID
    );

    let homeKits = options.sky.find(
      (sky) => sky.opta_ID === this.game.home_team.opta_ID
    );
    let homeKit = this.game.live_data.home_kit;
    let awayKits = options.sky.find(
      (sky) => sky.opta_ID === this.game.away_team.opta_ID
    );
    let awayKit = this.game.live_data.away_kit;
    let homeBGColour = options.game.home_team.variables["home_kit_bg"];
    let awayBGColour = options.game.away_team.variables["home_kit_bg"];
    let homeTextColour = options.game.home_team.variables["home_kit_text"];
    let awaytextColour = options.game.away_team.variables["home_kit_text"];
    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "012"; // home team back colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" + homeBGColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "013"; // home team text colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" +
      homeTextColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "001"; // home team
    }).value = options.game.home_team.code;

    xml.payload.field.find((item) => {
      return item.attr.name === "002"; // home score
    }).value =
      options.showTime !== 0
        ? options.game.live_data.home_team_score
        : options.game.live_data.homeScore_matchSelect;

    xml.payload.field.find((item) => {
      return item.attr.name === "014"; // away team back colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" + awayBGColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "015"; // away team text colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" +
      awaytextColour;

    xml.payload.field.find((item) => {
      return item.attr.name === "003"; // away score
    }).value =
      options.showTime !== 0
        ? options.game.live_data.away_team_score
        : options.game.live_data.awayScore_matchSelect;

    xml.payload.field.find((item) => {
      return item.attr.name === "004"; // away team
    }).value = options.game.away_team.code;

    xml.payload.field.find((item) => {
      return item.attr.name === "005"; // Show time
    }).value =
      options.showTime !== undefined ? options.showTime.toString() : "1";

    xml.payload.field.find((item) => {
      return item.attr.name === "006"; // start clock
    }).value = "";

    if (options.game.live_data.home_team_lineup) {
      xml.payload.field.find((item) => {
        return item.attr.name === "020"; // HOME RED CARDS
      }).value = options.game.live_data.home_team_lineup.reduce(
        (prev, curr) => {
          return (prev += curr.red_card ? 1 : 0);
        },
        0
      );
    }
    if (options.game.live_data.away_team_lineup) {
      xml.payload.field.find((item) => {
        return item.attr.name === "021"; // AWAY RED CARDS
      }).value = options.game.live_data.away_team_lineup.reduce(
        (prev, curr) => {
          return (prev += curr.red_card ? 1 : 0);
        },
        0
      );
    }

    // xml.payload.field.find((item) => {
    //   return item.attr.name === "006"; // start clock
    // }).value = "*TIME SET 2700.000,START,UPDATE_TEXT SET 0";

    await this.viz.updateData(this.page, xml);
  }

  async startClock() {
    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "006"; // start clock
    }).value = "*TIME SET 0.000,START,UPDATE_TEXT SET 0";
    this.viz.updateData(this.page, xml);
    //this.viz.take(this.page);
  }
  updateClock() {
    let period =
      this.game &&
      this.game.live_data &&
      this.game.live_data.periods &&
      this.game.live_data.periods.sort((a, b) => b.number - a.number)[0];
    if (period) {
      let time = moment.duration(
        period.kick_off ? Date.now() - period.kick_off : 0
      );
      if (period.number === 2) {
        time.add(45, "m");
      }
      if (period.number === 3) {
        // time.add(90, "m");
      }
      if (period.number === 4) {
        time.add(15, "m");
      }
      let showSponsor = this.sponsorTimes.findIndex(
        (s) =>
          this.onAir &&
          period.kick_off &&
          s.period === period.number &&
          time.minutes() === s.minute &&
          s.shown === false
      );

      if (showSponsor > -1) {
        this.sponsorTimes[showSponsor].shown = true;
        this.viz.sendCommand(
          "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET CLOCK_SPONSOR=1"
        );
      }

      let blmIndex = this.blmLogo.findIndex(
        (s) =>
          this.onAir &&
          s.period === period.number &&
          time.minutes() === s.minute &&
          time.seconds() === s.seconds &&
          s.shown === false
      );

      if (blmIndex > -1) {
        this.blmLogo[blmIndex].shown = true;
        this.viz.sendCommand(
          "trio:send_vizcmd FRONT_SCENE*STAGE*DIRECTOR*BLACK_LIVES_MATTER START"
        );
      }

      if (
        time.minutes() === 9 &&
        time.seconds() === 46 &&
        !this.animatedBLMOff
      ) {
        this.animatedBLMOff = true;
        this.viz.sendCommand(
          "trio:send_vizcmd FRONT_SCENE*STAGE*DIRECTOR*BLACK_LIVES_MATTER START REVERSE"
        );
      }

      if (period.added_time && this.onAir) {
        this.viz.sendCommand(
          "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET ADDEDTIMETEXT=" +
            period.added_time
        );
        if (
          !this.shownAddedTime &&
          ((time.minutes() + time.hours() * 60 >= 45 && period.number === 1) ||
            (time.minutes() + time.hours() * 60 >= 90 && period.number === 2) ||
            (time.minutes() + time.hours() * 60 >= 105 &&
              period.number === 3) ||
            (time.minutes() + time.hours() * 60 >= 115 && period.number === 4))
        ) {
          this.shownAddedTime = true;
          this.viz.sendCommand(
            "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET CLOCK_SPONSOR=1"
          );
          this.viz.sendCommand(
            "trio:send_vizcmd RENDERER*FRONT_LAYER*STAGE*DIRECTOR*ADDEDTIME START"
          );
        }
      }

      // if (period.number === 3 || (period.number === 4 && !this.shownET)) {
      //   this.viz.sendCommand(
      //     "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET CLOCK_ET=1"
      //   );
      // } else {
      //   this.viz.sendCommand(
      //     "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET CLOCK_ET=0"
      //   );
      // }

      // if (!this.clockStarted) {
      this.clockStarted = true;

      let seconds =
        time.seconds() + time.minutes() * 60 + time.hours() * 60 * 60;

      if (!period.kick_off) {
        this.shownAddedTime = false;
        this.addedTime = false;
        this.shownET = false;
        this.sponsorTimes = this.sponsorTimes.map((s) => {
          return { ...s, shown: false };
        });
        this.animatedBLMOff = false;
        this.blmLogo = this.blmLogo.map((s) => {
          return { ...s, shown: false };
        });
      }

      // this.viz.sendCommand(
      //   "trio:send_vizcmd CLOCK*DATA SET { 0 " +
      //     seconds +
      //     " 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }"
      // );
      //}
      if (
        (time.minutes() >= 45 && period.number === 1) ||
        (time.minutes() >= 90 && period.number === 2)
      ) {
        if (!this.addedTime) {
          this.addedTime = true;
          this.viz.sendCommand(
            "trio:send_vizcmd RENDERER*FRONT_LAYER*STAGE*DIRECTOR*OVERTIME START"
          );
        }
      } else {
        if (this.addedTime) {
          this.addedTime = false;
          this.viz.sendCommand(
            "trio:send_vizcmd RENDERER*FRONT_LAYER*STAGE*DIRECTOR*OVERTIME START REVERSE"
          );
        }
      }
    }
  }

  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
  update({ options }) {
    // this.game = options.game;
    switch (options.action) {
      case "START":
        let seconds = 0;
        if (options.period) {
          let period = options.period;
          let time = moment.duration(0);
          if (period.number === 2) {
            time.add(45, "m");
          }
          if (period.number === 3) {
            // time.add(90, "m");
          }
          if (period.number === 4) {
            time.add(15, "m");
          }
          seconds =
            time.seconds() + time.minutes() * 60 + time.hours() * 60 * 60;
        }
        this.viz.sendCommand(
          "trio:send_vizcmd CLOCK*DATA SET { 0 " +
            seconds +
            " 0 0 1 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }  { 0 0 0 0 0 }"
        );
        break;
    }
    // if (this.onAir) {
    //   options.showTime = this.showTime;
    //   options.updateClockTime = false;

    //   if (options.goal_time) {
    //     this.viz.sendCommand(
    //       "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET CLOCKDROPDOWN=GOAL TIME " +
    //         options.goal_time
    //     );
    //     this.viz.sendCommand(
    //       "trio:send_vizcmd RENDERER*FRONT_LAYER*STAGE*DIRECTOR*CLOCK_LINE2_IN_OUT START"
    //     );

    //     setTimeout(() => {
    //       this.viz.sendCommand(
    //         "trio:send_vizcmd RENDERER*FRONT_LAYER*STAGE*DIRECTOR*CLOCK_LINE2_IN_OUT CONTINUE"
    //       );
    //     }, 4000);
    //   } else {
    //     this.viz.sendCommand(
    //       "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET HOMECLOCKSCORE=" +
    //         options.game.live_data.home_team_score
    //     );
    //     this.viz.sendCommand(
    //       "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET AWAYCLOCKSCORE=" +
    //         options.game.live_data.away_team_score
    //     );
    //     this.viz.sendCommand(
    //       "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET HOMEREDCARDS=" +
    //         options.game.live_data.home_team_lineup.reduce((prev, curr) => {
    //           return (prev += curr.red_card ? 1 : 0);
    //         }, 0)
    //     );
    //     this.viz.sendCommand(
    //       "trio:send_vizcmd RENDERER*FUNCTION*DataPool*Data SET AWAYREDCARDS=" +
    //         options.game.live_data.away_team_lineup.reduce((prev, curr) => {
    //           return (prev += curr.red_card ? 1 : 0);
    //         }, 0)
    //     );
    //     //this.updateData({ options });
    //   }
    // }
  }
}
