export class Substitution {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Substitution";
    this.scene = "Substitution";
    this.data = [];
    this.page = 0;
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.options = options;

    this.data.push({
      name: "Shirt Number 1",
      value: {
        text: options.players[0]?.squad?.shirt_number || "",
      },
    });
    this.data.push({
      name: "First Name 1",
      value: {
        text: options.players[0]?.squad?.player.first_name || "",
      },
    });
    this.data.push({
      name: "Last Name 1",
      value: {
        text: options.players[0]?.squad?.player.last_name || "",
      },
    });
    this.data.push({
      name: "First Name Uppercase 1",
      value: {
        text: options.players[0]?.squad?.player.first_name_upper || "",
      },
    });
    this.data.push({
      name: "Last Name Uppercase 1",
      value: {
        text: options.players[0]?.squad?.player.last_name_upper || "",
      },
    });
    this.data.push({
      name: "Full Name 1",
      value: {
        text:
          (options.players[0]?.squad?.player.first_name || "") +
          " " +
          (options.players[0]?.squad?.player.last_name || ""),
      },
    });
    this.data.push({
      name: "Shirt Number 2",
      value: {
        text: options.players[1]?.squad?.shirt_number || "",
      },
    });
    this.data.push({
      name: "First Name 2",
      value: {
        text: options.players[1]?.squad?.player.first_name || "",
      },
    });
    this.data.push({
      name: "Last Name 2",
      value: {
        text: options.players[1]?.squad?.player.last_name || "",
      },
    });
    this.data.push({
      name: "First Name Uppercase 2",
      value: {
        text: options.players[1]?.squad?.player.first_name_upper || "",
      },
    });
    this.data.push({
      name: "Last Name Uppercase 2",
      value: {
        text: options.players[1]?.squad?.player.last_name_upper || "",
      },
    });
    this.data.push({
      name: "Full Name 2",
      value: {
        text:
          (options.players[1]?.squad?.player.first_name || "") +
          " " +
          (options.players[1]?.squad?.player.last_name || ""),
      },
    });
    this.data.push({
      name: "Subtitle",
      value: {
        text: "",
      },
    });

    this.data.push({
      name: "Team Code",
      value: {
        text: options.team?.code || "",
      },
    });

    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name || "",
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });
    let kit_text =
      options?.game?.home_team?._id === options.team?._id
        ? options.game?.home_team_kit + "_kit_bg"
        : options.game?.away_team_kit + "_kit_bg";

    this.data.push({
      name: "Team Kit BG Colour",
      value: {
        colour: options.team?.variables?.[kit_text] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour",
      value: {
        colour: options.team?.variables?.[kit_text] || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.page = 0;
    this.sendAnimateOff(this.scene, []);
  }
}
