import React from "react";
import styled from "styled-components";
import moment from "moment";
const Button = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #181d25;
  height: 5%;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: cetner;
`;
const Caption = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  text-align: center;
`;
export default function Player({
  player,
  previewGraphic,
  game,
  sky,
  countries,
  period,
}) {
  return (
    <>
      {/* {!player.title && period.number >= 2 && minutes >= 85 && (
        <Button
          onClick={() => {
            previewGraphic("Name", {
              player: player,
              subtext: "Man of the Match",
              team: team,
              sky: sky,
              countries: countries,
            });
          }}
        >
          MOTM
        </Button>
      )} */}
      <Button
        onClick={() => {
          previewGraphic("Name", {
            player: player,
            subtext: player?.team?.name,
            team: player?.team,
            sky: sky,
            countries: countries,
            game,
          });
        }}
      >
        Name
      </Button>

      {/* {player.type !== "coach" && (
        <Button
          onClick={() => {
            previewGraphic("Name", {
              player: player,
              team: team,
              sky: sky,
              subtext: team.name,
              countries: countries,
              shirt_number: player.shirt_number || "",
            });
          }}
        >
          Name with Number
        </Button>
      )} */}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 1,
              team: player?.team,

              countries: countries,
              game,
            });
          }}
        >
          Yellow Card
        </Button>
      )}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 2,
              team: player?.team,
              game,
              countries: countries,
              game,
            });
          }}
        >
          Double Yellow Card
        </Button>
      )}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 3,
              team: player?.team,
              game,
              countries: countries,
              game,
            });
          }}
        >
          Red Card
        </Button>
      )}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 1,
              team: player?.team,
              sky: sky,
              countries: countries,
              game,
              clock: true,
            });
          }}
        >
          U3 Yellow Card
        </Button>
      )}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 2,
              team: player?.team,
              sky: sky,
              countries: countries,
              game,
              clock: true,
            });
          }}
        >
          U3 Double Yellow Card
        </Button>
      )}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("Card", {
              player,
              cardType: 3,
              team: player?.team,
              sky: sky,
              countries: countries,
              game,
              clock: true,
            });
          }}
        >
          U3 Red Card
        </Button>
      )}
      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("POTM", {
              player,
              team: player?.team,
            });
          }}
        >
          POTM
        </Button>
      )}
      {player.stories &&
        player.stories.map((stat) => {
          return (
            <Button
              onClick={() => {
                previewGraphic("Name", {
                  team: player?.team,
                  player: player,
                  sky: sky,
                  subtext: stat.story,
                  countries: countries,
                  shirt_number: player.shirt_number || "",
                  game,
                });
              }}
            >
              <Caption>{stat.story}</Caption>
            </Button>
          );
        })}
    </>
  );
}
