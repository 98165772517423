import React from "react";
import styled from "styled-components";
import OptaEvent from "./OptaEvent";
import { ScrollBar } from "../../Theme/Hyper";
import moment from "moment";
const Main = styled.div`
  height: 100%;
  display: flex;
  height: 16vh;
  max-height: 170px;
  position: fixed;
  bottom: 0px;
`;
const ActionPanel = styled(ScrollBar)`
  display: flex;
  width: calc(100vw - 375px);
  margin-bottom: 1em;

  /* padding: 1em; */
  align-items: center;
  box-sizing: border-box;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  overflow-x: auto;
  padding: 0.5em;
  flex-direction: row-reverse;
`;

export default function Timeline({ matchEvents, previewGraphic, game, teams }) {
  let newMatchEvents = matchEvents;

  return (
    <Main>
      <ActionPanel
        onWheel={(e) => {
          if (e.deltaY > 0) e.currentTarget.scrollLeft += 100;
          else e.currentTarget.scrollLeft -= 100;
        }}
      >
        {newMatchEvents &&
          newMatchEvents.map((item, index) => {
            return (
              <OptaEvent
                index={index}
                total={newMatchEvents.length}
                matchEvent={item}
                previewGraphic={previewGraphic}
                game={game}
                teams={teams}
              />
            );
          })}
      </ActionPanel>
    </Main>
  );
}
