export class Card {
  constructor({ animate, preview, update, animateOff, triggerAnimation }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.name = "Card";
    this.scene = "L3_BadgeNumName";
    this.sceneYellow = "L3_BadgeNumName";
    this.sceneRed = "L3_BadgeNumName";
    this.data = [];
  }

  preview({ options }) {
    this.options = options;
    this.onAir = false;
    this.scene =
      options.cardType === 1 || options.cardType === 2
        ? this.sceneYellow
        : this.sceneRed;
    this.data = [
      {
        name: "FirstName",
        value: {
          text: options.player.first_name_upper,
        },
      },
      {
        name: "LastName",
        value: {
          text: options.player.last_name_upper,
        },
      },
      {
        name: "NUMBER",
        value: {
          text: options.player.shirt_number,
        },
      },
    ];

    if (options.team.variables["badge_home"]) {
      this.data.push({
        name: "HOME_BADGE",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" +
                (options.team.variables["badge_home"] &&
                  options.team.variables["badge_home"])
            ),
        },
      });
    }

    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
    if (this.options.cardType === 2) {
      this.timeout = setTimeout(() => {
        this.triggerAnimation(this.scene, this.data, "T3");
      }, 1000);
    }
  }
  animateOff({ options }) {
    clearTimeout(this.timeout);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
