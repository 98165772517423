import moment from "moment";

export class CrawlFormation {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "CrawlFormation";
    this.downloadPage = "7173";
    this.page = 6950;
    this.viz = viz;
    this.stage = 0;
    this.data = [];
    this.formation = "1532";
  }

  async preview({ options }) {
    this.onAir = false;
    this.page = 6950;
    this.stage = 0;
    let xml = await this.viz.getPage(this.downloadPage);

    let goals =
      options.team === 1
        ? options.game.live_data.home_scorers
        : options.game.live_data.away_scorers;
    let team =
      options.team === 1 ? options.game.home_team : options.game.away_team;

    let kits =
      options.team === 1
        ? options.sky.find(
            (sky) => sky.opta_ID === options.game.home_team.opta_ID
          )
        : options.sky.find(
            (sky) => sky.opta_ID === options.game.away_team.opta_ID
          );

    let kit =
      options.team === 1
        ? options.game.live_data.home_kit
        : options.game.live_data.away_kit;
    let homeBGColour =
      options.kits.find((k) => k.name === kits[kit + "KitViz"]) &&
      options.kits.find((k) => k.name === kits[kit + "KitViz"]).name;
    let homeTextColour =
      options.kits.find((k) => k.name === kits[kit + "ColourViz"]) &&
      options.kits.find((k) => k.name === kits[kit + "ColourViz"]).name;

    this.formation = options.formation.rows.split("");
    let players = options.lineup;
    players = players.sort((a, b) => {
      return a.order - b.order;
    });
    xml.payload.field.find((item) => {
      return item.attr.name === "6601"; // Team Name
    }).value = team.variables["name_uppercase"];

    xml.payload.field.find((item) => {
      return item.attr.name === "66012"; // Team Text Colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" + homeBGColour; //BLUE_13

    xml.payload.field.find((item) => {
      return item.attr.name === "66011"; // Team Text Colour
    }).value =
      "IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/UNIVERSAL/TEAM_COLOURS/" +
      homeTextColour;

    var formationRows = this.formation.split("");
    console.log(formationRows);
    var totalPlayerCount = 0;

    var loopstart = 0;
    // build individual pages for each row
    await formationRows.forEach(async (row, idx) => {
      // TURN OFF ALL PLAYERS (IN CASE THEY ARE ALREADY ON)
      for (let i = 1; i < 6; i++) {
        xml.payload.field.find((item) => {
          return item.attr.name === "111" + i; // Manager Title
        }).value = "0";
      }

      // loop through starting 11 (Players left)
      var pagePlayerCount = 0;

      for (let i = loopstart; i < parseInt(row) + loopstart; i++) {
        var activateTab = "111" + (pagePlayerCount + 1);
        var numTab = "11" + (pagePlayerCount + 2) + "1";
        var lastTab = "11" + (pagePlayerCount + 2) + "2";
        var bookedTab = "11" + (pagePlayerCount + 2) + "3";
        var goalsTab = "11" + (pagePlayerCount + 2) + "7";
        var subbedTab = "11" + (pagePlayerCount + 2) + "8";
        //console.log(loopstart, totalPlayerCount, parseInt(row), (parseInt(row) + loopstart));
        //console.log(activateTab, numTab, lastTab, bookedTab, goalsTab, subbedTab);

        let player = players.find((p) => p.order === i + 1);

        xml.payload.field.find((item) => {
          return item.attr.name === activateTab; //  Activate Player
        }).value = player ? "1" : "0";

        if (player) {
          xml.payload.field.find((item) => {
            return item.attr.name === numTab; // Number
          }).value = player.shirt_number || 0;

          xml.payload.field.find((item) => {
            return item.attr.name === lastTab; // surname
          }).value =
            player.player.last_name_upper +
            "    " +
            (player.captiain ? "(C)" : "");

          xml.payload.field.find((item) => {
            return item.attr.name === bookedTab; //  Player booked?
          }).value = player.yellow_card ? "1" : "0";

          let goal = goals.filter((g) => g.player === player.player._id);

          xml.payload.field.find((item) => {
            return item.attr.name === goalsTab; //  Player scored? Goal: 23’ | Goals: 23’,45’,82’ |   Goal: 23’ (pen)
          }).value = goal
            .map((g, index) => {
              {
                let time = moment.duration(g.time);
                let mins = time.minutes() + time.hours() * 60;
                let output = "";
                if (index === 0) {
                  output = "Goal: ";
                }
                if (index > 1) {
                  output += ",";
                }
                output += mins + "'";
                return output;
              }
            })
            .join();
          //"Goal: " + totalPlayerCount;

          xml.payload.field.find((item) => {
            return item.attr.name === subbedTab; //  Player subbed? come on
          }).value = player.subbed ? "1" : "0";
        }
        totalPlayerCount++;
        pagePlayerCount++;
      }
      loopstart = totalPlayerCount;
      console.log(
        "*************************************************write page:",
        this.page + idx
      );

      await this.viz.updateData(this.page + idx, xml);
    });
    this.page = 6950;
    this.viz.preview(this.page);
  }
  animate({ options }) {
    this.onAir = true;

    this.viz.take(this.page);
    this.page++;
    this.viz.preview(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.stage = 0;
    this.viz.takeOut(this.page - 1);
  }
}
