import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { APIContext } from "../../contexts/APIContext";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { Button } from "../../Theme/Hyper";

const TeamNamePanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 7vh;
`;
const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  display: flex;
  width: 45%;
  height: 72px;
`;
const Middle = styled.div`
  display: flex;
  width: 10%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -10px;
`;
const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  margin-left: ${(props) => (props.home ? "auto" : "")};
  display: flex;
  font-size: 1.5em;
  cursor: pointer;
`;
const Score = styled.div`
  display: flex;
  font-size: 2em;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: center;
  border-radius: 2px;
`;

const Red = styled.div`
  display: flex;
  width: 40px;
  height: 20px;
  background-color: ${({ selected }) => (selected ? "#d93232" : "")};
  border: 1px solid #d93232;
  margin: 5px;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    background-color: #d93232;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ScoreButton = styled(Button)`
  width: 100px;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  height: 100%;
  box-sizing: border-box;
  margin: 0px;
`;
export default function EFLTeamNames({
  setGoalScored,
  game,
  setSelectedPlayer,
  group,
}) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { updateGame } = useContext(APIContext);
  const [homeReds, setHomeReds] = useLocalStorage("home_reds");
  const [awayReds, setAwayReds] = useLocalStorage("away_reds");

  let homeBGColour =
    game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] || "";
  let awayBGColour =
    game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] || "";

  return (
    <TeamNamePanel>
      <Team
        style={{
          borderBottom:
            "3px solid " + (homeBGColour ? homeBGColour : "rgba(0,0,0,0)"),
          opacity:
            !selectedTeam || selectedTeam._id === game.home_team._id
              ? "1"
              : ".2",
        }}
      >
        <Column>
          <TeamName>{game.home_team.name}</TeamName>
          <div>{game.home_formation?.name}</div>
        </Column>
        <Column>
          <Row style={{ marginLeft: "auto" }}>
            <ScoreButton
              onClick={() => {
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];
                  if (game.away_scorers && game.away_scorers.length > 0) {
                    newScorers = newScorers.concat(
                      game.away_scorers.filter((g) => g.own_goal)
                    );
                  }

                  let latestGoals = newScorers.sort((a, b) => {
                    return b.time - a.time;
                  });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
              }}
            >
              -
            </ScoreButton>
            <Score
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            >
              {game?.home_scorers?.length || 0}
            </Score>
            <ScoreButton
              onClick={() => {
                let periods = [...(game.live_data?.periods || [])];
                let period = periods.sort((a, b) => b.number - a.number)[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 2) {
                  time += 1000 * 60 * (game?.config?.half_duration || 45);
                }
                if (period.number === 3) {
                  time += 1000 * 60 * ((game?.config?.half_duration || 45) * 2);
                }
                if (period.number === 4) {
                  time += 1000 * 60 * 105;
                }
                let _id = Date.now();

                updateGame({
                  id: game._id,
                  home_scorers: [...game.home_scorers, { time: time, _id }],
                });
              }}
            >
              +
            </ScoreButton>
          </Row>
        </Column>
      </Team>
      <Middle></Middle>
      <Team
        style={{
          borderBottom:
            "3px solid " + (awayBGColour ? awayBGColour : "rgba(0,0,0,0)"),
          opacity:
            !selectedTeam || selectedTeam._id === game.away_team._id
              ? "1"
              : ".2",
        }}
      >
        <Column>
          <Row style={{ marginRight: "auto" }}>
            <ScoreButton
              onClick={() => {
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];
                  if (game.home_scorers && game.home_scorers.length > 0) {
                    newScorers = newScorers.concat(
                      game.home_scorers.filter((g) => g.own_goal)
                    );
                  }

                  let latestGoals = newScorers.sort((a, b) => {
                    return b.time - a.time;
                  });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
              }}
            >
              -
            </ScoreButton>
            <Score
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            >
              {game?.away_scorers?.length || 0}
            </Score>
            <ScoreButton
              onClick={() => {
                let periods = [...(game.live_data?.periods || [])];
                let period = periods.sort((a, b) => b.number - a.number)[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 2) {
                  time += 1000 * 60 * (game?.config?.half_duration || 45);
                }
                if (period.number === 3) {
                  time += 1000 * 60 * ((game?.config?.half_duration || 45) * 2);
                }
                if (period.number === 4) {
                  time += 1000 * 60 * 105;
                }
                let _id = Date.now();

                updateGame({
                  id: game._id,
                  away_scorers: [...game.away_scorers, { time: time, _id }],
                });
              }}
            >
              +
            </ScoreButton>
          </Row>
        </Column>
        <Column style={{ alignItems: "end" }}>
          <TeamName>{game.away_team.name}</TeamName>
          <div>{game.away_formation?.name}</div>
        </Column>
      </Team>
    </TeamNamePanel>
  );
}
