export class Name {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "Name";
    this.page = "7101";
    this.data = [];
    this.viz = viz;
  }

  async preview({ options }) {
    this.onAir = false;

    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "0004"; // No. of subtext lines
    }).value = "1";

    if (
      options.subtext.toUpperCase() === "MAN OF THE MATCH" ||
      options.show_lion
    ) {
      xml.payload.field.find((item) => {
        return item.attr.name === "100"; // Badge
      }).value =
        "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\PREMIER_LEAGUE_LION_PURPLE.png";
    } else {
      xml.payload.field.find((item) => {
        return item.attr.name === "100"; // Badge
      }).value =
        "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" +
          options.team.variables["badge"] || "";
    }

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // first name
    }).value = options.player.shirt_number || "";

    xml.payload.field.find((item) => {
      return item.attr.name === "102"; // first name
    }).value =
      options.player.first_name_upper ||
      options.player.first_name.toUpperCase();

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // surname
    }).value =
      options.player.last_name_upper || options.player.last_name.toUpperCase();

    xml.payload.field.find((item) => {
      return item.attr.name === "104"; // NAT Tricode
    }).value =
      "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
      (options.player.nationality &&
        options.player.nationality.code.toLowerCase());

    xml.payload.field.find((item) => {
      return item.attr.name === "200"; // sub text
    }).value = options.subtext || "";

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
