export class Substitute {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "Substitute";
    this.page = "7110";
    this.data = [];
    this.viz = viz;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.viz.getPage(this.page);
    options.players = options.players.sort((a, b) => a.order - b.order);
    xml.payload.field.find((item) => {
      return item.attr.name === "0004"; // No. of subtext lines
    }).value = "1";

    let sky = options.sky.find(
      (sky) => sky.opta_ID === options.players[0].team.opta_ID
    );

    xml.payload.field.find((item) => {
      return item.attr.name === "100"; // Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + options.team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // Number
    }).value = options.players[0].shirt_number;

    xml.payload.field.find((item) => {
      return item.attr.name === "102"; // first name
    }).value = options.players[0].first_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // surname
    }).value = options.players[0].last_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "104"; // NAT Tricode
    }).value =
      "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
      options.players[0].nationality.code.toLowerCase();

    xml.payload.field.find((item) => {
      return item.attr.name === "121"; // Number
    }).value = options.players[1].shirt_number;

    xml.payload.field.find((item) => {
      return item.attr.name === "122"; // first name
    }).value = options.players[1].first_name.toUpperCase();

    xml.payload.field.find((item) => {
      return item.attr.name === "123"; // surname
    }).value = options.players[1].last_name.toUpperCase();

    xml.payload.field.find((item) => {
      return item.attr.name === "124"; // NAT Tricode
    }).value =
      "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
      options.players[1].nationality.code.toLowerCase();

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    if (this.onAir) {
      this.viz.continue(this.page);
    } else {
      this.viz.take(this.page);
    }
    this.onAir = true;
  }
  animateOff({ options }) {
    this.viz.takeOut(this.page);
  }
}
