export class Card {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "Card";
    this.page = "7102";
    this.viz = viz;
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "0004"; // No. of subtext lines
    }).value = "1";

    let sky = options.sky.find((sky) => sky.opta_ID === options.team.opta_ID);

    xml.payload.field.find((item) => {
      return item.attr.name === "100"; // Badge
    }).value =
      "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + options.team.variables["badge"];

    xml.payload.field.find((item) => {
      return item.attr.name === "101"; // Number
    }).value = options.player.shirt_number;

    xml.payload.field.find((item) => {
      return item.attr.name === "102"; // first name
    }).value = options.player.first_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // surname
    }).value = options.player.last_name_upper;

    xml.payload.field.find((item) => {
      return item.attr.name === "104"; // NAT Tricode
    }).value =
      "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
      options.player.nationality.code.toLowerCase();

    xml.payload.field.find((item) => {
      return item.attr.name === "200"; // sub text
    }).value = options.sub_text
      ? options.sub_text
      : options.cardType === 1
      ? "Booked"
      : "Sent Off";

    xml.payload.field.find((item) => {
      return item.attr.name === "107"; // card number (no. of yellow / red cards)
    }).value = options.number_of_cards;

    xml.payload.field.find((item) => {
      return item.attr.name === "108"; // card select 0=off, 1=yellow, 2= Dbl. Yellow, 3=red
    }).value = options.cardType;

    await this.viz.updateData(this.page, xml);
    this.viz.preview(this.page);
  }
  animate({ options }) {
    this.onAir = true;
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
