export class Standings {
  constructor({ viz }) {
    this.onAir = false;
    this.name = "Standings";
    this.page = "7200";
    this.data = [];
    this.viz = viz;
    this.half = 0;
    this.data = {};
  }

  async preview({ options }) {
    this.data = options;
    this.onAir = false;
    let xml = await this.viz.getPage(this.page);

    xml.payload.field.find((item) => {
      return item.attr.name === "00001"; // transition -1 move up, 0 - slide in, 1 - move down
    }).value = -1;

    options.standings.rankings
      .slice(this.half * 10, this.half * 10 + 10)
      .forEach((row, i) => {
        let team = options.sky.find((s) => s.uuid === row.team_id);

        // only set for 10 per page
        /* Row info
             010 highlight: off, white, gold, red (1,2,3,4)
             0110 badge
             0111 arrow down, level, up (-1,0,1)
             0120 pos
             0121 team
             0122 played
             0123 goal diff
             0124 pts
             */
        var hlTab = (i + 1 + "0").padStart(3, "0");
        var badgeTab = (i + 1 + "10").padStart(4, "0");
        var arrowTab = (i + 1 + "11").padStart(4, "0");
        var arrowTextTab = (i + 1 + "12").padStart(4, "0");
        var posTab = (i + 1 + "20").padStart(4, "0");
        var teamTab = (i + 1 + "21").padStart(4, "0");
        var pldTab = (i + 1 + "22").padStart(4, "0");
        var gdTab = (i + 1 + "23").padStart(4, "0");
        var ptsTab = (i + 1 + "24").padStart(4, "0");

        xml.payload.field.find((item) => {
          return item.attr.name === hlTab; // highlight: off, white, gold, red (1,2,3,4)
        }).value = "1";

        xml.payload.field.find((item) => {
          return item.attr.name === arrowTextTab; // C - CHAMPIONS
        }).value = "";

        xml.payload.field.find((item) => {
          return item.attr.name === badgeTab; // badge
        }).value =
          "F:\\MEDIA\\FOOTBALL\\TEAM_BADGES\\" + team.variables["badge"];

        var arrowTabNode = xml.payload.field.find((item) => {
          return item.attr.name === arrowTab;
        }); // Tab Group

        arrowTabNode.value = row.position - row.last_position;

        arrowTabNode.field.value = "1";

        xml.payload.field.find((item) => {
          return item.attr.name === posTab; // pos
        }).value = row.position;

        xml.payload.field.find((item) => {
          return item.attr.name === teamTab; // Team
        }).value = team.Name;

        xml.payload.field.find((item) => {
          return item.attr.name === pldTab; // Played
        }).value = row.matches_played;

        xml.payload.field.find((item) => {
          return item.attr.name === gdTab; // GD
        }).value = row.goal_difference;

        xml.payload.field.find((item) => {
          return item.attr.name === ptsTab; // PTS
        }).value = row.points;
      });

    await this.viz.updateData(this.page, xml);
  }
  animate({ options }) {
    this.viz.take(this.page);
    this.half = 1;

    this.preview({ options: this.data });
  }
  animateOff({ options }) {
    this.half = 0;
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
