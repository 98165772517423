import moment from "moment";

export class Fixtures {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Fixtures";
    this.scene = "Fixtures";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [];

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    options?.fixtures?.map((game, index) => {
      let row = index + 1;

      this.data.push({
        name: "Game " + row + " Home Team Short Name",
        value: { text: game?.home_team?.short_name || "" },
      });
      this.data.push({
        name: "Game " + row + " Home Team Name",
        value: { text: game?.home_team?.name || "" },
      });
      this.data.push({
        name: "Game " + row + " Home Team Badge",
        value: { image: game?.home_team?.variables?.badge || "" },
      });

      this.data.push({
        name: "Game " + row + " Home Team Score",
        value: { text: game?.home_scorers?.length || "0" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Name",
        value: { text: game?.away_team?.name || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Short Name",
        value: { text: game?.away_team?.short_name || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Badge",
        value: { image: game?.away_team?.variables?.badge || "" },
      });
      this.data.push({
        name: "Game " + row + " Away Team Score",
        value: { text: game?.away_scorers?.length || "0" },
      });
      this.data.push({
        name: "Game " + row + " Date",
        value: { text: game?.date || "" },
      });

      this.data.push({
        name: "Game " + row + " Status",
        value: {
          text:
            game?.live_data?.periods?.filter((p) => p.kick_off !== null)
              ?.length > 0
              ? "started"
              : "notstarted",
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
