export class Sub {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    triggerAnimation,
    clearPreview,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.clearPreview = clearPreview;
    this.name = "Substitute";
    this.scene = "CLOCK_SUB_1";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.team =
      options.team._id === options.game.home_team._id ? "home" : "away";

    this.data = [
      { name: "Home Team", value: { text: options.game.home_team.code } },
      { name: "Away Team", value: { text: options.game.away_team.code } },
      {
        name: "HOME_TEAM",
        value: {
          visible: this.team === "home" ? true : false,
        },
      },
      {
        name: "AWAY_TEAM",
        value: {
          visible: this.team === "away" ? true : false,
        },
      },
      {
        name: "NAME",
        value: {
          text: options.players[0].last_name_upper,
        },
      },
      {
        name: "NUMBER",
        value: {
          text: options.players[0].shirt_number,
        },
      },
      {
        name: "NAME_2",
        value: {
          text: options.players[1].last_name_upper,
        },
      },
      {
        name: "NUMBER_2",
        value: {
          text: options.players[1].shirt_number,
        },
      },
    ];
    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    if (this.onAir) {
      this.triggerAnimation(this.scene, this.data, "T3");
    } else {
      this.sendAnimate(this.scene, this.data);
      this.sendPreview(this.scene, 50, this.data, "T3");
    }
    this.onAir = true;
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
    this.clearPreview(this.name);
  }
}
