export class TwoSubstitution {
  constructor({ animate, preview, update, animateOff, clearPreview }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.clearPreview = clearPreview;
    this.name = "TwoSubstitution";
    this.scene = "Two Substitution";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    this.options = options;
    this.onAir = false;
    this.data = [];
    this.options = options;
    let playerIndex = 0;

    if (this.page === 1) {
      this.options.subs.forEach((sub, index) => {
        playerIndex++;
        this.buildPlayer(sub.players[0], playerIndex);
      });
      this.options.subs.forEach((sub, index) => {
        playerIndex++;
        this.buildPlayer(sub.players[1], playerIndex);
      });
    } else {
      this.options.subs.forEach((sub, index) => {
        playerIndex++;
        this.buildPlayer(sub.players[1], playerIndex);
      });
      this.options.subs.forEach((sub, index) => {
        playerIndex++;
        this.buildPlayer(sub.players[0], playerIndex);
      });
    }

    this.data.push({
      name: "Subtitle",
      value: {
        text: "",
      },
    });

    this.data.push({
      name: "Team Code",
      value: {
        text: options.team?.code,
      },
    });

    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name || "",
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });

    let kit_text =
      options?.game?.home_team?._id === options.team?._id
        ? options.game?.home_team_kit + "_kit_bg"
        : options.game?.away_team_kit + "_kit_bg";

    this.data.push({
      name: "Team Kit BG Colour",
      value: {
        colour: options.team?.variables?.[kit_text] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour",
      value: {
        colour: options.team?.variables?.[kit_text] || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  buildPlayer(player, playerIndex) {
    this.data.push({
      name: "Shirt Number " + playerIndex,
      value: {
        text: player?.squad?.shirt_number || "",
      },
    });
    this.data.push({
      name: "First Name " + playerIndex,
      value: {
        text: player?.squad?.player.first_name || "",
      },
    });
    this.data.push({
      name: "Last Name " + playerIndex,
      value: {
        text: player?.squad?.player.last_name || "",
      },
    });
    this.data.push({
      name: "First Name Uppercase " + playerIndex,
      value: {
        text: player?.squad?.player.first_name_upper || "",
      },
    });
    this.data.push({
      name: "Last Name Uppercase " + playerIndex,
      value: {
        text: player?.squad?.player.last_name_upper || "",
      },
    });
    this.data.push({
      name: "Full Name " + playerIndex,

      value: {
        text:
          (player?.squad?.player.first_name || "") +
          " " +
          (player?.squad?.player.last_name || ""),
      },
    });
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
    if (this.page === 1) {
      this.page = 2;
      this.preview({ options: this.options });
    }
  }
  animateOff({ options }) {
    this.clearPreview(this.scene);
    this.page = 1;
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
