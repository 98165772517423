export class FFFormation {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFFormation";
    this.scene = "FF - Formation";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [
      {
        name: "TITLE_TEXT",
        value: {
          text: options.game[options.team + "_team"].variables[
            "name_uppercase"
          ],
        },
      },
      {
        name: "HOME_BADGE",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" +
                options.game[options.team + "_team"].variables["badge_home"]
            ),
        },
      },
      {
        name: "SPONSOR_IMG",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Sponsors/" + options.game.competition.logo || "blank.png"
            ),
        },
      },
    ];
    debugger;
    this.data.push({
      name: "MAIN_GRP",
      value: {
        position: { x: -120, y: 530 },
      },
    });

    this.data.push({
      name: "FOOTER_TEXT",
      value: {
        text:
          options.game[options.team + "_team"].variables["manager_title"] +
          ": " +
          options.game[options.team + "_team"].variables["manager_first"] +
          " " +
          options.game[options.team + "_team"].variables["manager_last"],
      },
    });

    options.game.live_data[options.team + "_team_lineup"]
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order <= 11)

      .forEach((player, index) => {
        this.data.push({
          name: "NUMBER_" + (index + 1),
          value: {
            text: player.shirt_number,
          },
        });
        this.data.push({
          name: "NAME_" + (index + 1),
          value: {
            text: player.last_name_upper,
          },
        });
        let y =
          Math.sign(
            options.game.live_data[options.team + "_formation"].positions[index]
              .y
          ) == -1
            ? Math.abs(
                options.game.live_data[options.team + "_formation"].positions[
                  index
                ].y
              )
            : -options.game.live_data[options.team + "_formation"].positions[
                index
              ].y;
        this.data.push({
          name: "PLAYER_" + (index + 1),
          value: {
            visible: true,
            position: {
              x:
                options.game.live_data[options.team + "_formation"].positions[
                  index
                ].x * 9,
              y: y * 4,
            },
          },
        });
      });
    options.game.live_data[options.team + "_team_lineup"]
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order > 11)
      .forEach((player, index) => {
        this.data.push({
          name: "NUMBER_" + (index + 12),
          value: {
            text: player.shirt_number,
          },
        });
        this.data.push({
          name: "NAME_" + (index + 12),
          value: {
            text: player.last_name_upper,
          },
        });
        this.data.push({
          name: "PLAYER_" + (index + 12),
          value: {
            visible: true,
          },
        });
      });

    debugger;
    let num = options.game.live_data[options.team + "_team_lineup"]
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order > 11).length;
    new Array(
      12 -
        options.game.live_data[options.team + "_team_lineup"]
          .sort((a, b) => a.order - b.order)
          .filter((p) => p.order > 11).length
    )
      .fill(1)
      .map((i, index) => {
        this.data.push({
          name: "PLAYER_" + (23 - index),
          value: {
            visible: false,
          },
        });
      });
    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
